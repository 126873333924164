import React, { useState } from 'react';
import axios from 'axios';
 
const TestFor = () => { 


  
  const [transactionId ] = useState('TXN'+Date.now());
  const [name] = useState();
  const [product] = useState('Basic');
  const [mail] = useState('test@gmail.com');
  const [amount] = useState(247);
  const [hash , setHash] = useState();  
  const [merchentkey ] = useState('C6RvXU')
   const [user_id ] = useState(20)
  const getHash = async (event) => {  
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_MAIN_SERVER}/api/hash`, {
        name:name,
        email:mail,
        transactionId:transactionId,
        product:product,
        amount:amount,
        user_id:user_id
      }); 
      setHash(response.data.hash);  
      setTimeout(() => {
        document.getElementById('payuForm').submit();
      }, 0);
    } catch (error) {
      console.error('Error initiating payment:', error);
       
    }
  } 

 

  return (  
    <>
      <div>
       <form  id="payuForm" action='https://test.payu.in/_payment' method='post' onSubmit={getHash}>
        <input type="hidden" name="key" value={merchentkey} />
        <input type="hidden" name="txnid" value={transactionId} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="productinfo" value={product} />
        <input type="hidden" name="firstname" value={name} />
        <input type="hidden" name="email" value={mail} />
        <input type="hidden" name="surl" value="http://localhost:8085/api/success" />
        <input type="hidden" name="furl" value="http://localhost:8085/api/failure" />
        <input type="hidden" name="udf1" value={user_id} />
         <input type="hidden" name="hash" value={hash} />
         <input type="submit" value="submit"/> 
       </form>
      </div>
      
    </>
  );
}

export default TestFor;
