import React, { useState } from "react";
import OpenAI from "openai";
import { useEffect } from "react";
import { useResume } from "../../context/Resume";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import HashLoader from "react-spinners/HashLoader";
import { useJobs } from "../../context/Jobs";
import Model from "../../../../../components/Model";
import styles from "./Config.module.css";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const AIPoints = ({ sectionKey, element, partiCularName, section }) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [modelshowskills, setModalShowskills] = useState(false);
  const { handleResumeChange } = useResume();

  const [jobRole, setJobRole] = useState("");
  const [skills, setSkills] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [company, setCompany] = useState("");
  const [current, setCurrent] = useState("");
  const [arrays, setArray] = useState([]);
  const [tickPoint, setTickPoint] = useState(false);
  const [pointArray, setPointArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);
  const [jobDetails, setJobDetails] = useState();
  const { payment } = useJobs();
  useEffect(() => {
    setJobRole(element.role);
    setCompany(element.company);
    setTimePeriod(element.timePeriod);
    setSkills(element?.techstack);
  }, [
    element.company,
    element.role,
    element.company,
    element.timePeriod,
    element?.techstack,
  ]);

  const generatePoint = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
          },
          {
            role: "user",
            content: `job role= ${jobRole} company = ${company} Time Period=${timePeriod} Skills= ${skills}`,
          },
          {
            role: "user",
            // content: `give 8 point and include percentage wise point  while i am in this job role so i can add in my resume  and provide me only array `,
            content: `give me 8 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setCurrent(jobRole);
      handleResumeChange(sectionKey, [...(section || []), ""]);
      setArray(suggestions);
      setLoadingAi(false);
    } catch (error) {
      console.log(error);
    }
  };

  const generateSkills = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure  only skills filter out from given job details ",
          },
          { role: "user", content: `job Details= ${jobDetails}` },
          {
            role: "user",
            content: `give 8  skills in array in one or two words `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const skillsString = completion.choices[0].message.content;
      console.log("skills", skillsString);

      // Attempt to parse the skills string to an array
      let suggestions;
      try {
        suggestions = JSON.parse(skillsString);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        suggestions = skillsString.split(",").map((skill) => skill.trim());
      }

      // Ensure suggestions is an array
      if (!Array.isArray(suggestions)) {
        throw new Error("Parsed suggestions are not an array");
      }

      // Update the resume section with new skills
      handleResumeChange(sectionKey, [...(section || []), ...suggestions]);
      setLoadingAi(false);
      setModalShowskills(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pointHandler = (index, point) => {
    setTickPoint(!tickPoint);
    setPointArray((pointArray) => [
      ...pointArray, // Spread the previous array
      { index: index, content: point }, // Add the new object
    ]);
  };

  const removePointHandler = (indexToRemove) => {
    setTickPoint(!tickPoint);
    setPointArray((pointArray) => {
      const newArray = [...pointArray]; // Create a copy of the previous array
      newArray.splice(indexToRemove, 1); // Remove the item at the specified index
      return newArray; // Return the updated array
    });
  };

  useEffect(() => {
    const extractedContentArray = pointArray?.map((item) => item.content);
    setContentArray(extractedContentArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointArray]);

  useEffect(() => {
    contentArray?.map((value, index) => {
      handleResumeChange(`${sectionKey}.${index}`, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentArray]);

  // now getting all the point set it to the vlues and local storage

  return (
    <div className="d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle className={styles.aibuttt} id="dropdown-basic">
          {loadingAi ? <HashLoader color="white" size={25} /> : "AI Writer"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setModalShow(true)
            }
          >
            Generate Content
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setModalShowskills(true)
            }
            style={{ color: "rgb(249, 111, 89)" }}
          >
            Add Job Description
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate Points With AI <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
           className={styles.modelBody}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12  ">
                <div className={styles.mb}>
                  <label
                    htmlFor="field2"
                    className={styles.label}
                  >
                    Job Roles:
                  </label>
                  <input
                    type="text"
                    name="field2"
                    id="field2"
                    placeholder="Job Roles"
                     className={styles.jobRole}
                    value={jobRole}
                    onChange={(e) => setJobRole(e.target.value)}
                  />
                </div>
                <div className={styles.mb}>
                  <label
                    htmlFor="field2"
                    className={styles.label}
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="field2"
                    id="field2"
                    placeholder="Company Name"
                     className={styles.jobRole}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>

                <div className={styles.mb}>
                  <label
                    htmlFor="field2"
                    className={styles.label}
                  >
                    Tech Stack
                  </label>
                  <input
                    type="text"
                    name="field2"
                    id="field2"
                    placeholder="Skills e.g c++ , html "
                     className={styles.jobRole}
                    value={skills}
                    onChange={(e) => setSkills(e.target.value)}
                  />
                </div>

                <div className={styles.mb}>
                  <label
                    htmlFor="field2"
                    className={styles.label}
                  >
                    Time Period
                  </label>
                  <input
                    type="text"
                    name="field2"
                    id="field2"
                    placeholder="Job Roles"
                     className={styles.jobRole}
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                  />
                </div>

                <Button
                  style={{
                    background:
                      "linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%)",
                  }}
                  onClick={() => generatePoint()}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
              <div
                className="col-12   p-1 mt-4"
                style={{
                  backgroundColor: "#f9faff",
                  borderRadius: "8px",
                  border: "1px solid #838fa0",
                }}
              >
                <p className="text-center m-3 " style={{ fontWeight: "500" }}>
                  Showing Result For{" "}
                  <span style={{ color: "blue", fontWeight: "600" }}>
                    {current}
                  </span>{" "}
                </p>
                <ul style={{ listStyle: "none", paddingLeft: "0rem" }}>
                  {arrays.map((point, index) => (
                    <div
                      className="p-2 m-2 d-flex"
                      style={{
                        fontSize: "14px",
                        border: "2px solid #dee2e6",
                        borderRadius: "9px",
                        backgroundColor: "white",
                      }}
                    >
                      <span className="d-flex justify-content-center align-items-center p-2 m-1">
                        {/* {tickPoint? <FontAwesomeIcon onClick={() => pointHandler(index , point)} icon={faCheck} /> :   <FontAwesomeIcon onClick={() => removePointHandler(index  )} icon={faPlus} /> }    */}
                        {pointArray.some((item) => item.index === index) ? (
                          <FontAwesomeIcon
                            onClick={() => removePointHandler(index)}
                            icon={faCheck}
                          />
                        ) : (
                          <FontAwesomeIcon
                            onClick={() => pointHandler(index, point)}
                            icon={faPlus}
                          />
                        )}
                      </span>
                      <li key={index}>{point}</li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelshowskills}
        onHide={() => setModalShowskills(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate With AI <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className={styles.mb}>
                  <label
                    htmlFor="jobDescription"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Job Description:
                  </label>
                  <textarea
                    name="jobDescription"
                    id="jobDescription"
                    placeholder="Enter the job roles and responsibilities"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      fontSize: "16px",
                      resize: "vertical",
                      minHeight: "100px",
                    }}
                    value={jobDetails}
                    onChange={(e) => setJobDetails(e.target.value)}
                  />
                </div>
                <Button
                  style={{
                    background:
                      "linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%)",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  onClick={() => generateSkills()}
                  disabled={loadingAi}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AISkillsPoints = ({
  sectionKey,
  element,
  partiCularName,
  section,
}) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modelshowskills, setModalShowskills] = useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const { handleResumeChange } = useResume();
  const { payment } = useJobs();
  const [jobRole, setJobRole] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [company, setCompany] = useState("");
  const [current, setCurrent] = useState("");
  const [arrays, setArray] = useState([]);
  const [tickPoint, setTickPoint] = useState(false);
  const [pointArray, setPointArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);
  const [jobDetails, setJobDetails] = useState();

  useEffect(() => {
    setJobRole(element.role);
    setCompany(element.company);
    setTimePeriod(element.timePeriod);
  }, [element.role, element.company, element.timePeriod]);

  const generatePoint = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be  there",
          },
          {
            role: "system",
            content:
              "Provide  ans in most famous technical skills and some tools",
          },
          {
            role: "user",
            content: `Position= ${jobRole}  and provide me skills on that basis `,
          },
          {
            role: "user",
            content: `give 20 point  and    while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setCurrent(jobRole);
      handleResumeChange(sectionKey, [...(section || []), ""]);
      setArray(suggestions);
      setLoadingAi(false);
    } catch (error) {
      console.log(error);
    }
  };

  const generateSkills = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure  only skills filter out from given job details ",
          },
          { role: "user", content: `job Details= ${jobDetails}` },
          {
            role: "user",
            content: `give 8  skills in array in one or two words `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const skillsString = completion.choices[0].message.content;
      console.log("skills", skillsString);

      // Attempt to parse the skills string to an array
      let suggestions;
      try {
        suggestions = JSON.parse(skillsString);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        suggestions = skillsString.split(",").map((skill) => skill.trim());
      }

      // Ensure suggestions is an array
      if (!Array.isArray(suggestions)) {
        throw new Error("Parsed suggestions are not an array");
      }

      // Update the resume section with new skills
      handleResumeChange(sectionKey, [...(section || []), ...suggestions]);
      setLoadingAi(false);
      setModalShowskills(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pointHandler = (index, point) => {
    setTickPoint(!tickPoint);
    setPointArray((pointArray) => [
      ...pointArray, // Spread the previous array
      { index: index, content: point }, // Add the new object
    ]);
  };

  const removePointHandler = (indexToRemove) => {
    setTickPoint(!tickPoint);
    setPointArray((pointArray) => {
      const newArray = [...pointArray]; // Create a copy of the previous array
      newArray.splice(indexToRemove, 1); // Remove the item at the specified index
      return newArray; // Return the updated array
    });
  };

  useEffect(() => {
    const extractedContentArray = pointArray?.map((item) => item.content);
    setContentArray(extractedContentArray);
  }, [pointArray]);

  useEffect(() => {
    contentArray?.map((value, index) => {
      handleResumeChange(`${sectionKey}.${index}`, value);
    });
  }, [contentArray]);

  // now getting all the point set it to the vlues and local storage

  return (
    <div className="d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle className={styles.drop} id="dropdown-basic">
          {loadingAi ? <HashLoader color="white" size={25} /> : "AI Writer"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setModalShow(true)
            }
          >
            Generate Content
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setModalShowskills(true)
            }
            style={{ color: "rgb(249, 111, 89)" }}
          >
            Add Job Description
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate Technical Skills <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={styles.modelBody}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12  ">
                <div className={styles.mb}>
                  <label
                    htmlFor="field2"
                     className={styles.label}
                  >
                    Job Roles:
                  </label>
                  <input
                    type="text"
                    name="field2"
                    id="field2"
                    placeholder="Job Roles"
                    className={styles.jobRole}
                    value={jobRole}
                    onChange={(e) => setJobRole(e.target.value)}
                  />
                </div>
                <Button
                  style={{
                    background:
                      "linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%)",
                  }}
                  onClick={() => generatePoint()}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
              {current && (
                <div className={`col-12   p-1 mt-4 ${styles.da}`}>
                  <p className="text-center m-3 " style={{ fontWeight: "500" }}>
                    Showing Result For{" "}
                    <span style={{ color: "blue", fontWeight: "600" }}>
                      {current}
                    </span>{" "}
                  </p>

                  <ul style={{ listStyle: "none", paddingLeft: "0rem" }}>
                    {arrays.map((point, index) => (
                      <div className={`p-2 m-2 d-flex ${styles.rq}`}>
                        <span className="d-flex justify-content-center align-items-center p-2 m-1">
                          {/* {tickPoint? <FontAwesomeIcon onClick={() => pointHandler(index , point)} icon={faCheck} /> :   <FontAwesomeIcon onClick={() => removePointHandler(index  )} icon={faPlus} /> }    */}
                          {pointArray.some((item) => item.index === index) ? (
                            <FontAwesomeIcon
                              onClick={() => removePointHandler(index)}
                              icon={faCheck}
                            />
                          ) : (
                            <FontAwesomeIcon
                              onClick={() => pointHandler(index, point)}
                              icon={faPlus}
                            />
                          )}
                        </span>
                        <li key={index}>{point}</li>
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelshowskills}
        onHide={() => setModalShowskills(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate With AI <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className={styles.mb}>
                  <label
                    htmlFor="jobDescription"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Job Description:
                  </label>
                  <textarea
                    name="jobDescription"
                    id="jobDescription"
                    placeholder="Enter the job roles and responsibilities"
                    className={styles.jasole}
                    value={jobDetails}
                    onChange={(e) => setJobDetails(e.target.value)}
                  />
                </div>
                <Button
                  className={styles.buttons}
                  onClick={() => generateSkills()}
                  disabled={loadingAi}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AiDescription = ({ element, updateDescription }) => {
  const { setLoading } = useResume();
  const { payment } = useJobs();
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [customModel, setCustomModel] = useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [compay, setCompany] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [input, setInput] = useState("");
  const [description, setDescription] = useState(element?.description);
  useEffect(() => {
    setCompany(element?.company);
    setJobRole(element?.role);
    setTimePeriod(element?.timePeriod);
  }, [element]);

  const generateDesc = async () => {
    try {
      setLoading(true);
      setLoadingAi(true);
      setModalShow(false);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes.",
          },
          {
            role: "user",
            content: `company= ${compay}, job roles= ${jobRole}, time period= ${timePeriod}.`,
          },
          {
            role: "user",
            content: `so i alreday provide you the company name job roles and timeperiod . Please provide me job descripiton for the that in 30 to 40 words  in description first write techonoly i used by sepataing "|" , what you do there and how did you do , give it me in paragarph`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });
      let aiResoponse = completion.choices[0].message.content;
      setDescription(aiResoponse);
      setLoading(false);
      setLoadingAi(false);
    } catch (error) {
      console.error(error);
    }
  };

  const AddPhrase = async () => {
    try {
      setLoading(true);
      setLoadingAi(true);
      setCustomModel(false);
      // Add user message to the conversation
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes.",
          },
          {
            role: "user",
            content: `UserInput= ${input}`,
          },
          {
            role: "user",
            content: `so i alreday provide you the  userPhrase . Please provide me job descripiton for the that in 30 to 40 words  in description first write techonoly i used by sepataing "|" , what you do there and how did you do , give it me in paragarph`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      // Add AI response to the conversation
      let aiResoponse = completion.choices[0].message.content;

      setDescription(aiResoponse);

      setLoading(false);
      setLoadingAi(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // handleResumeChange(sectionKey,description)
    updateDescription(description);
  }, [description]);

  return (
    <div className={styles.mian}>
      <Dropdown>
        <Dropdown.Toggle className={styles.aibuttt} id="dropdown-basic">
          {loadingAi ? <HashLoader color="white" size={25} /> : "AI Writer"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setModalShow(true)
            }
          >
            Generate Content
          </Dropdown.Item>
          <Dropdown.Item
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => generateDesc
            }
          >
            Rewrite Section
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className={styles.dropdowncolor}
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalPaymentShow(true)
                : () => setCustomModel(true)
            }
          >
            Add Your Phrases
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Body className={styles.modelBody}>
          <div className={styles.mb}>
            <label htmlFor="field1" className={styles.label}>
              Company:
            </label>
            <input
              type="text"
              name="field1"
              id="field1"
              placeholder="Company"
              className={styles.jobRole}
              value={compay}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className={styles.mb}>
            <label htmlFor="field2" className={styles.label}>
              Job Roles:
            </label>
            <input
              type="text"
              name="field2"
              id="field2"
              placeholder="Job Roles"
              className={styles.jobRole}
              value={jobRole}
              onChange={(e) => setJobRole(e.target.value)}
            />
          </div>
          <div className={styles.mb}>
            <label htmlFor="field3" className={styles.label}>
              Time Period:
            </label>
            <input
              type="text"
              name="field3"
              id="field3"
              placeholder="Time Period"
              className={styles.jobRole}
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            />
          </div>
          <Button className={styles.backgrounddd} onClick={generateDesc}>
            Generate
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={customModel}
        onHide={() => setCustomModel(false)}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Give Prompt To AI</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
        >
          <div className={styles.mb}>
            <label htmlFor="field1" className={styles.label}></label>
            <textarea
              type="text"
              name="field1"
              id="field1"
              placeholder="I was in Robato System and Worked for 2 year ....."
              className={styles.jobRole}
              onChange={(e) => setInput(e.target.value)}
            />
          </div>

          <Button className={styles.backgrounddd} onClick={AddPhrase}>
            Generate
          </Button>
        </Modal.Body>
      </Modal>

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

