import React, { useState } from 'react'
import styles from "../../assets/Matching.module.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CircleCheck, CircleHelp,  Sparkle, TriangleAlert } from 'lucide-react';
import OpenAI from "openai";
import { useResume } from './components/context/Resume';
import ReactSpeedometer from "react-d3-speedometer"
import { useJobs } from './components/context/Jobs';
import Model from '../../components/Model';
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const Matching = () => {
  const [value , setValue] = useState(''); 
  const [response ,setResponse] = useState('');
  const [ats ,setAts] = useState('');
  const [sugg,setSugg] = useState('');
  const [key ,setKey] = useState('');  
  const [skills,setSkills] = useState(''); 
  const [sum,setSum] = useState(''); 
  const [good , setGood] = useState(''); 
  const [loading , setLoading] = useState(false); 
  const {values} = useResume();  
  const {payment} = useJobs(); 
  const [modalShow, setModalShow] = useState(false);
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props} className={styles.custom}>
     We match candidate resumes to job descriptions, generating ATS scores and improvement tips for better alignment with the role.
  </Tooltip>
);
   
const handleSubmit = async () => {
  const jsonString = JSON.stringify(values);

  // Retrieve previous results from localStorage
  let previousResults = JSON.parse(localStorage.getItem('previousResults')) || {};

  try {
    setLoading(true);

    // Fetch new completion from the AI
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You are an automatic tracking software for resumes. You have to provide ATS Score as 'ats', good points as 'good', suggestions as 'sugg' in an array, missing skills as 'skills', missing keywords as 'keyword' in an array format, and provide a summary as 'sum' after comparing job details and resume content. Remember previous results and increase the score when users make suggested changes. The structure of the response must be in JSON format, with each section in array format. Ensure the response is detailed and 100% accurate."
        },
        {
          role: "system",
          content:
            "Structure of the response: {'ats': number, 'good': [string], 'sugg': [string], 'skills': [string], 'keyword': [string], 'sum': string}. Provide details and maximum points in each section."
        },
        { 
          role: "user", 
          content: `job Details= ${value}` 
        },
        { 
          role: "user", 
          content: `Resume Content= ${jsonString}` 
        },
        { 
          role: "user", 
          content: "Once again recheck and give me the response. The ATS score should range from 0 to 100." 
        },
        { 
          role: "user", 
          content: "Give a detailed response in each section." 
        },
      ],
      model: "gpt-3.5-turbo",
      temperature: 1,
      max_tokens: 1000,
    });

    let data = completion.choices[0].message.content;
    const json_data = JSON.parse(data);

     
    const resumeContent = jsonString.toLowerCase();
    const previousKeywords = previousResults.keyword || [];
    const previousSuggestions = previousResults.sugg || [];
    let foundKeywords = previousKeywords.filter(keyword => resumeContent.includes(keyword.toLowerCase()));
    let addressedSuggestions = previousSuggestions.filter(suggestion => resumeContent.includes(suggestion.toLowerCase()));

    
    if (foundKeywords.length > 0) {
      json_data.ats += foundKeywords.length * 1;  
    }
    if (addressedSuggestions.length > 0) {
      json_data.ats += addressedSuggestions.length * 1;  
    }
    json_data.ats = Math.min(json_data.ats, 100);  

     
    json_data.keyword = json_data.keyword.filter(keyword => !foundKeywords.includes(keyword));
    json_data.sugg = json_data.sugg.filter(suggestion => !addressedSuggestions.includes(suggestion));

    
    localStorage.setItem('previousResults', JSON.stringify({
      ats: json_data.ats,
      good: json_data.good,
      sugg: json_data.sugg,
      skills: json_data.skills,
      keyword: json_data.keyword,
      sum: json_data.sum
    }));

    setResponse(JSON.stringify(json_data, null, 2)); // Update the response with the modified JSON data
    setAts(json_data.ats);
    setSugg(json_data.sugg);
    setKey(json_data.keyword);
    setGood(json_data.good);
    setSkills(json_data.skills);
    setSum(json_data.sum);

    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};




 
  //  useEffect(()=>{
  //    console.log("this is response",response)
  //  },[response])

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        Matching 
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className={styles.do}><CircleHelp size={18} /></span>
        </OverlayTrigger> 
        <div className={styles.dor}></div>
      </div>     
        
        
                  {response && (
                     <div className={styles.spedocont}>
                     <ReactSpeedometer
                         maxValue={100}
                         value={ats}
                         needleColor="red"
                         startColor="blue"
                         segments={10}
                         endColor="green" 
                         
                       /> 
                        <div className={styles.dfld}>
                        <div className={styles.buttoncontainwer}>
                            <div className={styles.buttonb} onClick={()=>setResponse('')}>Recheck</div>
                        </div> 
                        {/* <div className={styles.buttoncontainwer}>
                        <div className={styles.buttong} onClick={handleSubmit}>{loading  ? "Loading" :"Recheck"}</div>
                        </div>  */}
                        </div>
                        </div>

                  )}
                
        
     {response ? (
            <div className={styles.lloooo}> 
                {/* // sum section  */}
                <div>   
                   {sum}  
                </div> 
            </div>
     ) :
      <div className={styles.tot}>
     <div className={styles.content}> 
      <div className={styles.jd}>
        Job Descriptions
        </div> 
        <div className={styles.test}>
          <textarea
                name="postContent"
                value={value}
                onChange={(e)=>setValue(e.target.value)} 
                placeholder='Job Description....'
                className={styles.area}
              />
        </div> 
       
   </div> 
    <div className={styles.buttoncontainwer}>
           <div className={styles.buttons} onClick={payment?.paymentStatus !== "success" ? () => setModalShow(true) : handleSubmit} >{loading  ? "Loading" :"Scan"}</div>
           
        </div> 
   </div>
   }
       
       


         
        {response && (

                  <div className={styles.alllor}>
                      <h5 className={styles.ffo}>{sugg &&  "Matching Suggestions"}</h5>
                      <ul className={styles.loos}>
                          {sugg?.map((item, index) => (
                                <li key={index} className={styles.lso}> <Sparkle size={14} /> {item}</li>
                            ))}
                      </ul>
                        

                        <h2 className={styles.ffo}> {key &&  "Missing Keywords"} </h2>
                      <div className={styles.dd}>
                        {key?.map((item, index) => (
                          <span key={index} className={styles.dfdf}> <TriangleAlert size={14} color={"white"}/> {item}</span>
                          ))}
                      </div> 
                       
                        <h2 className={styles.ffo}> {good && "Good In Resume"} </h2>
                        <ul className={styles.loos}>
                          {good?.map((item, index) => (
                            <li key={index} className={styles.lso}> <CircleCheck size={14} color={"green"}/> {item}</li>
                            ))}
                        </ul>

                        <h2 className={styles.ffo}>{skills && "Required Skills "} </h2>
                        <ul className={styles.loos}> 
                          {skills?.map((item, index) => (
                            <li key={index} className={styles.lso}> <TriangleAlert size={14} color={"orange"}/> {item}</li>
                            ))}
                        </ul>
                        

                       

                  </div>
        )}
          <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  )
}

export default Matching