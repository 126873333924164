import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuth } from "./ResumeBuilder/builder/components/context/auth";
import axios from "axios";
import styles from "./assets/Login.module.css";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import HashLoader from "react-spinners/HashLoader";
import HelmetConfig from "./components/HelmetConfig";

const Login = () => { 
 
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const Navigate = useNavigate();
  //  const location = useLocation();
  const { auth, setAuth } = useAuth();
  // console.log(useAuth);
  let [loading, setLoading] = useState(false);
  if(auth?.token!=='') {
    Navigate("/welcome/dashboard");
  } 
  const handleNavigateForgot = () => {
    Navigate("/forgot-password");
  };
  const handleNavigate = () => {
    Navigate("/signup");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/login`,
        { email, password }
      );
      
       
      if (res.data.success) {
        toast.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });

        localStorage.setItem("auth", JSON.stringify(res.data));
        Navigate("/welcome/dashboard");
      } else {
         
        toast.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong!!!");
    }
  };

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      // console.log('Google Auth Response:', credentialResponse);

      // Decode the JWT token
      const decode = await jwtDecode(credentialResponse.credential);
      

      // Make a POST request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/loginWithGoogle`,
        { decode }
      );

      // console.log('Backend Response:', response.data);
      // Handle further actions after successful login, such as updating UI, storing tokens, etc.

      if (response.data.success) {
        toast.success(response.data.message);

        const updatedAuth = {
          ...auth,
          user: response.data.user,
          token: response.data.token,
          pic: decode?.picture,
          message: response.data.message, 
          success: response.data.success
        };
  
        setAuth(updatedAuth);
  
        localStorage.setItem("auth", JSON.stringify(updatedAuth));
  
        Navigate("/welcome/dashboard");
        
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error scenarios, such as displaying error messages to the user
    }
  };

  const responseGoogleFailure = (error) => {
    console.error("Google Auth Error:", error);
    // Handle error scenarios, such as displaying error messages to the user
  };
 

  useGoogleOneTapLogin({
    onSuccess: responseGoogleSuccess,
    onFailure: responseGoogleFailure,
    onError: () => {
      console.log("Login Failed");
    },
  });

  const handleClick = async(value) =>{
    Navigate(`/`);
 }

  return (
    <>
      <HelmetConfig
          title="MyFuse Login | Access Your Account and Sign In"
          description="Log in to MyFuse to access your account, manage job applications, and more. Follow our guide for secure sign-in and troubleshoot any login issues."
          keywords="
          Myfuse Login, Sign In Myfuse, Login to Myfuse.in, Myfuse User Login, Myfuse Account Access,
          How to Login to Myfuse.in, Myfuse Login for Job Seekers, Myfuse Account Sign In Guide, Trouble Logging into Myfuse, How to Access Myfuse Account,
          Login, Sign In, Access Account, Myfuse Login, User Login,
          Account Access, User Sign In, Login Page, Myfuse Account, Secure Login,
          Myfuse Sign In Help, Logging into Myfuse.in Account, Myfuse Login Issues, Myfuse Secure Account Access, Quick Login Myfuse,
          How to Quickly Login to Myfuse, Myfuse Login Process for Users, Accessing Myfuse Account Securely, Myfuse Login for Returning Users, Myfuse User Account Access Guide,
          Login Help, User Account Login, Myfuse User Access, Sign In to Myfuse, Secure Account Login
        "
          
          canonical="https://myfuse.in/signup"
        />
    <div className={`container-fluid  ${styles.main}`}>
      <div
        className={`row `}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-12 col-md-6"
          style={{ backgroundColor: "white", height: "100vh", zIndex: "2" }}
        >
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-11 col-md-7 ">
              <div onClick={handleClick} style={{cursor:"pointer"}}>
                <h1 className={` text-center ${styles.logo}`}>
                  {" "}
                  MyFuse<span style={{ color: "#f96f59" }}>.</span>In
                </h1>
              </div>
              <div className="d-flex justify-content-center">
                {/* <GoogleLogin
                                onSuccess={credentialResponse => { 
                                  console.log(credentialResponse)
                                  const decode = jwtDecode(credentialResponse.credential);
                                    console.log(decode);
                                    const res = axios.post(`https://myfuseback.vercel.app/api/auth/loginWithGoogle` ,{decode}) ; 
                                }}
                                onError={() => {
                                  console.log('Login Failed');
                                }}
                              /> */}

                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Login with Google"
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </div>
              <br />
              <p className="text-center">or</p>

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    className={`form-control shadow-none ${styles.inputstyle}`}
                    id="exampleInputemail"
                    placeholder="Email "
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control shadow-none input-style"
                    id="exampleInputPassword1"
                    placeholder="Password "
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    required
                  />
                  {/* <span className={styles.note}>
                    Note: You can use your myfuse.in credentials to access this
                    platform as well.
                  </span> */}
                </div>

                {loading ? (
                  <div className="text-center d-flex justify-content-center">
                    {" "}
                    <div
                      className={` d-flex  justify-content-center ${styles.btn}`}
                    >
                      <HashLoader color="#000000" size={25} />
                    </div>
                  </div>
                ) : (
                  <div className="text-center ">
                    {" "}
                    <button type="submit" className={`${styles.btn}`}>
                      Sign In
                    </button>
                  </div>
                )}

                <p
                  className="text-center mt-3 "
                  style={{
                    fontWeight: "bold",
                    color: "#4D85D5",
                    cursor: "pointer",
                  }}
                  onClick={handleNavigateForgot}
                >
                  Forgot Password ?
                </p>

                <p
                  className="text-center mt-4"
                  style={{ fontWeight: 500, cursor: "pointer" }}
                  onClick={handleNavigate}
                >
                  Haven’t got an account?{" "}
                  <span style={{ color: "#4D85D5" }}>Sign Up</span>
                </p>
              </form>
            </div>
          </div>
        </div>

        <div className={`col-12 col-md-6 ${styles.hideOnMobile} `}>
          <div className={`${styles.rectangle}`}></div>
          <div className={`${styles.square}`}></div>
          <div className={`${styles.circle}`}></div>
          <div
            className="row d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className={`col-11 col-md-7 ${styles.reviewtext}`}>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png"
                style={{ width: "4rem" }} 
                alt="sasd"
              />
              "Your future starts here. Effortlessly craft an exceptional resume with MyFuse.in – where unmatched quality meets seamless simplicity."{" "}
              <br />
              <span style={{ fontSize: "13px" }}> - CEO Myfuse </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Login;
