import React from "react";
import styles from "./IITCResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faBriefcase,
  faMailForward,
  faLink
} from "@fortawesome/free-solid-svg-icons";

const IITCResume = ({ sections }) => {
  return (
    <div className={styles.resume}>
      <Header
        profile={sections.profile.data}
        iitb_logo={sections.settings?.data.logo}
      />
      <Education
        education={sections.education?.data}
        settings={sections.settings?.data}
      />
      <Experience
        experience={sections.experience?.data}
        settings={sections.settings?.data}
      />
       <Projects
        projects={sections.projects?.data}
        settings={sections.settings?.data}
      />
       <TechnicalSkills
        technicalSkills={sections.technicalSkills?.data}
        settings={sections.settings?.data}
      />
      <Achievements
        achievements={sections.achievements?.data}
        settings={sections.settings?.data}
      />
      <Awards
        awards={sections.awards?.data}
        settings={sections.settings.data}
      />
     
      <PORs
        pors={sections.positionsOfResponsibility?.data}
        settings={sections.settings?.data}
      />
     
      <ExtraCurriculars
        extraCurriculars={sections.extraCurriculars?.data}
        settings={sections.settings?.data}
      />
    </div>
  );
};

const Header = ({ profile}) => {
  if (!profile) return null;
  return (
    <div className={styles.header}>
      <div className={styles.user_details}>
        <div className={styles.left}>
        <h1 className={styles.header_text} >{profile.name}</h1>
           <h1 className={styles.header_gray}> {profile.currentrole}   {profile.company}</h1>
        </div>  
        <div className={styles.right}> 
            <span>  <FontAwesomeIcon icon={faPhone} style={{  color: "gray" }} /> <a href={`${profile.linkedin}`}>{profile.phone}</a>  </span> 
            <span>  <FontAwesomeIcon icon={faMailForward} style={{  color: "gray" }} />  <a href={`mailto:${profile.email}`}>{profile.email}</a> </span> 
            <span>  <FontAwesomeIcon icon={faBriefcase} style={{  color: "gray" }} />     <a href={`${profile.linkedinUsername}`}>{profile.linkedinUsername}</a> </span> 
            <span>  <FontAwesomeIcon icon={faLink} style={{  color: "gray" }} />  <a href={` ${profile.githubUsername}`}>{profile.githubUsername}</a> </span> 
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children, settings }) => {
  if (!children) return null;
  return (
    <div className={styles.section} >
      {title &&
        (settings.format === "1" ? (
          <h2>
            {title.split(" ").map((ele) => (
              <>
                <span>{ele[0]}</span>
                {ele.slice(1)}
              </>
            ))}
            <div className={styles.divider}></div>
          </h2>
        ) : (
         <h2>
            {title.split(" ").map((ele) => (
              <>
                <span>{ele[0]}</span>
                {ele.slice(1)}
              </>
            ))}
            <div className={styles.divider}></div>
          </h2>
        ))}
      <div className={styles.section_container}>{children}</div>
    </div>
  );
};

const Points = ({ points, years, markdown = true, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.point}>
          {markdown ? (
            <>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i>{years[index]}</i>}
            </>
          ) : (
            <>
              <div>{point}</div>
              {years && <i>{years[index]}</i>}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience, settings }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <Section>

     <div style={{display:'flex' , flexDirection:'row' }}>
          {/* this is first 20 %? */}
            <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
                <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
            </div>

            {/* this is for the second 20% */}
            <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
            <h5>Experience</h5>
            </div>
            
    </div>
      
      {experience?.map((exp, index) => (
          <div style={{display:'flex' , flexDirection:'row' }}>
          {/* this is first 20 %? */}
            <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
              <p style={{ textAlign: 'end' }}>
              {exp.timePeriod.split('-').map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index !== exp.timePeriod.split('-').length - 1 && '-'} {/* Always render the hyphen */}
                  {index !== exp.timePeriod.split('-').length - 1 && <br />} {/* Render <br> if it's not the last part */}
                </React.Fragment>
              ))}
            </p>

            </div>

            {/* this is for the second 20% */}
            <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
            <span>{exp.role}  {exp.role?',':''} {exp.company} {exp.company?',':'' }  {exp.location}</span> <br></br>
              <i>
                {exp.description}
              </i>
             <Points points={exp.points} markdown={true} secondary={true} />
            </div>
    </div>

      ))}
    </Section>
  );
};

const Projects = ({ projects, settings }) => {
  if (!projects || projects.length === 0) return null;
  return (

    <Section>

    <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
               <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
           <h5>Projects</h5>
           </div>
           
   </div>
     
     {projects?.map((project, index) => (
         <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
             <p style={{ textAlign: 'end' }}>
             {project.year.split('-').map((part, index) => (
               <React.Fragment key={index}>
                 {part}
                 {index !== project.year.split('-').length - 1 && '-'} {/* Always render the hyphen */}
                 {index !== project.year.split('-').length - 1 && <br />} {/* Render <br> if it's not the last part */}
               </React.Fragment>
             ))}
           </p>

           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
           <span>{project.title}  {project.organisation?',':''} {project.organisation} {project.organisation?',':'' }  {''}</span> <br></br>
             <i>
               {project.description}
             </i>
             <Points points={project.points} markdown={true} secondary={true} />
           </div>
   </div>

     ))}
   </Section>
   
  );
};

const Education = ({ education}) => {
  if (!education || education.length === 0) return null;
  return (
    <Section>
     
    <div style={{display:'flex' , flexDirection:'row' }}>
          {/* this is first 20 %? */}
            <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
                <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
            </div>

            {/* this is for the second 20% */}
            <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
            <h5>Education</h5>
            </div>
            
    </div>
    {education.map((edu, index) => (
    <div style={{display:'flex' , flexDirection:'row' }}>
          {/* this is first 20 %? */}
            <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
                <td>{edu.year}</td>
            </div>

            {/* this is for the second 20% */}
            <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
          <div style={{display:'flex' ,justifyContent:'space-between'}}>  <span>{edu.degree}  {edu.degree?'in':''} {edu.branch}</span> <span style={{fontWeight:'400'}}> {edu.cgpa ? `CGPA ${edu.cgpa}` : `${edu.percentage}%`}
</span>  </div>    
             <i> {edu.college}</i>
            </div>
    </div>
     ))}
    </Section>
  );
};

const TechnicalSkills = ({ technicalSkills, settings }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <Section >
        
        <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
               <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
           <h5>Skills</h5>
           </div>
           
          </div>

      {technicalSkills.map((tech, index) => (
             <div style={{display:'flex' , flexDirection:'row' }}>
             {/* this is first 20 %? */}
               <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
                 <p style={{ textAlign: 'end' }}>
                 {tech.name}
               </p>
               </div>
    
               {/* this is for the second 20% */}
               <div style={{ width: '85%', marginLeft: '1rem' }}>
                {tech.skills?.join(",")}
              </div>
       </div>


        // <div key={index} >
        //   <strong>{tech.name}</strong>: {tech.skills?.join(",")}
        // </div>
      ))}
    </Section>
  );
};

const ExtraCurriculars = ({ extraCurriculars, settings }) => {
  if (!extraCurriculars || extraCurriculars.length === 0) return null;
  return (
    <Section>

         <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
               <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
           <h5>Extra Curriculars</h5>
           </div>
           
          </div>
        
          <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
             
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
           <Points
              points={extraCurriculars.map((point) => point.description)}
              years={extraCurriculars.map((point) => point.year)}
              markdown={true}
            />
           </div>
   </div>
     
    </Section>
  );
};

const Achievements = ({ achievements, settings }) => {
  if (!achievements || achievements.length === 0) return null;
  return (
    <Section>

            <div style={{display:'flex' , flexDirection:'row' }}>
                    {/* this is first 20 %? */}
                      <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
                          <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
                      </div>

                      {/* this is for the second 20% */}
                      <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
                      <h5>Achievements</h5>
                      </div>
                      
              </div>


              <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
           <Points
                years={achievements.map((point) => point.year)}
            />
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
           <Points
              points={achievements.map((point) => point.description)}
              markdown={true}
            />
           </div>
   </div>




                 
    </Section>
  );
};

const Awards = ({ awards, settings }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <Section >
              <div style={{display:'flex' , flexDirection:'row' }}>
                    {/* this is first 20 %? */}
                      <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
                          <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
                      </div>       

                      {/* this is for the second 20% */}
                      <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
                      <h5>Scholarships and Awards</h5>
                      </div>
                      
              </div>
              <div style={{display:'flex' , flexDirection:'row' }}>
         {/* this is first 20 %? */}
           <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
           <Points
                 years={awards.map((point) => point.year)}
            />
           </div>

           {/* this is for the second 20% */}
           <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
           <Points
              points={awards.map((point) => point.description)}
            />
           </div>
   </div>
      
    </Section>
  );
};

const PORs = ({ pors, settings }) => {
  if (!pors || pors.length === 0) return null;
  return (
    <Section>
<            div style={{display:'flex' , flexDirection:'row' }}>
                    {/* this is first 20 %? */}
                      <div style={{width:'15%'  , display:'flex' , alignItems:'center'}}>
                          <div style={{border:'3px solid gray',width:'100%', height:'1px'}}></div>
                      </div>

                      {/* this is for the second 20% */}
                      <div style={{width:'85%'  ,  color:'gray' , marginLeft:'1rem'}}>
                      <h5>Positions of Responsibility</h5>
                      </div>
                      
              </div>



      {pors?.map((por, index) => {
        return (


          <div style={{display:'flex' , flexDirection:'row' }}>
          {/* this is first 20 %? */}
            <div style={{width:'15%'  , display:'flex' , justifyContent:'end'}}>
              <p style={{ textAlign: 'end' }}>
              {por.year.split('-').map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index !== por.year.split('-').length - 1 && '-'} {/* Always render the hyphen */}
                  {index !== por.year.split('-').length - 1 && <br />} {/* Render <br> if it's not the last part */}
                </React.Fragment>
              ))}
            </p>
 
            </div>
 
            {/* this is for the second 20% */}
            <div style={{width:'85%'  ,  marginLeft:'1rem'}}>
            <i>{por.title} </i> <br></br>
              <Points points={por.points} markdown={true} secondary={true} />
            </div>
             </div>

          // <div className={styles.project} key={index}>
          //   <div className={styles.firstline}>
          //     <h3 className={styles.title}>{por.title}</h3>
          //     <i>{por.year}</i>
          //   </div>
          //   <div
          //     style={{
          //       marginLeft: "1rem",
          //     }}
          //   >
          //     <Points points={por.points} markdown={true} secondary={true} />
          //   </div>
          // </div>
        );
      })}
    </Section>
  );
};

export default IITCResume;
