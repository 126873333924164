import React from "react";
import styles from "./IITGResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";

const IITGResume = ({ sections }) => {
  return (
    <div className={styles.resume}>
      <Header profile={sections.profile.data} />
      <About profile={sections.profile.data} />
      <Experience experience={sections.experience?.data} />
      <Projects projects={sections.projects?.data} />
      <Education education={sections.education?.data} />
      <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
      <Awards awards={sections.awards?.data} />
      <Achievements achievements={sections.achievements?.data} />
      <PORs pors={sections.positionsOfResponsibility?.data} />
      <ExtraCurriculars extraCurriculars={sections.extraCurriculars?.data} />
    </div>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div>
      <h2 className={styles.nameashu}>{profile.name}</h2>
      <span className={styles.resumesampletexttop}>
        {profile.email} <span className={styles.dot}>•</span> {profile.phone}{" "}
        <span className={styles.dot}>•</span> {profile.linkedinUsername}{" "}
        <span className={styles.dot}>•</span> {profile.linkedinUsername}
      </span>
    </div>
  );
};

const About = ({ profile }) => {
  if (!profile) return null;
  return (
    <div>
      <div className={styles.secondline}>
        <span className={styles.postionashu}> {profile.position} </span>
        <span className={styles.resumesampletextabout}>{profile.aboutme}</span>
      </div>
    </div>
  );
};

const Points = ({ points, years, markdown = true, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          {markdown ? (
            <>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i>{years[index]}</i>}
            </>
          ) : (
            <>
              <div>{point}</div>
              {years && <i>{years[index]}</i>}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}>Experience</h5>
      {experience?.map((exp, index) => (
        <div className={styles.project} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{exp.role}</h3>
            <span className={styles.resumesampletextt}>{exp.timePeriod}</span>
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh3}>
              <span> {exp.company} </span>{" "}
            </h3>
            <span className={styles.resumesampletextt}>{exp.location}</span>
          </div>

          <i>{exp.description}</i>
          <div>
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}>Projects</h5>

      {projects?.map((project, index) => (
        <div className={styles.project} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{project.title}</h3>
            <span className={styles.resumesampletextt}>{project.year}</span>
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh3}>
              <span> {project.organisation} </span>{" "}
            </h3>
            <span className={styles.resumesampletextt}></span>
          </div>

          <i>{project.description}</i>

          <div>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}>Education</h5>

      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </span>
            <span>
              {" "}
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `Percentage ${edu.percentage}%`
                : ""}
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}> Skills</h5>

      {technicalSkills.map((tech, index) => (
        <div key={index}>
          <strong>{tech.name}</strong>: <br />{" "}
          <span style={{ color: "gray" }}>{tech.skills?.join(".")}</span>
        </div>
      ))}
      {/* <div style={{ height: "0.7rem" }}></div> */}
      <br />
    </div>
  );
};

const ExtraCurriculars = ({ extraCurriculars }) => {
  if (!extraCurriculars || extraCurriculars.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}>ExtraCurriculars</h5>

      <div>
        <Points
          points={extraCurriculars.map((point) => point.description)}
          years={extraCurriculars.map((point) => point.year)}
          markdown={true}
        />
      </div>
    </div>
  );
};

const Achievements = ({ achievements }) => {
  if (!achievements || achievements.length === 0) return null;
  return (
    <>
      <h5 className={styles.resumesamplsectionheading}> Achievements</h5>

      <div>
        <Points
          points={achievements.map((point) => point.description)}
          years={achievements.map((point) => point.year)}
          markdown={true}
        />
      </div>
    </>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <>
      <h5 className={styles.resumesamplsectionheading}> Awards</h5>

      <div>
        <Points
          points={awards.map((point) => point.description)}
          years={awards.map((point) => point.year)}
          markdown={true}
        />
      </div>
    </>
  );
};

const PORs = ({ pors }) => {
  if (!pors || pors.length === 0) return null;
  return (
    <div>
      <h5 className={styles.resumesamplsectionheading}>Responsibility</h5>

      {pors?.map((por, index) => {
        return (
          <div className={styles.project} key={index}>
            <div className={styles.doreeoe}>
              <h3 className={styles.title}>{por.title}</h3>
              <span>{por.year}</span>
            </div>
            <div>
              <Points points={por.points} markdown={true} secondary={true} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IITGResume;
