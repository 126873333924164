import React from "react";
import MyResponsiveNavbar from "./Navbar";
import styles from "../assets/Job.module.css";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import Footer from "./Footer";
import AboveNavbar from "../components/AboveNavbar";
import BottomBar from "../components/BottomBar";
import HelmetConfig from "../components/HelmetConfig";
import Heading from "../components/DashBoard.js/Heading";
import UseOurAI from "../components/DashBoard.js/UseOurAI";
import JobsByRole from "../components/DashBoard.js/JobsByRole";
import RecomandedJobs from "../components/DashBoard.js/RecomandedJobs";
import LeftWala from "../components/DashBoard.js/LeftWala";
import RightWala from "../components/DashBoard.js/RightWala";
import Compnay from "../components/DashBoard.js/Compnay";
import Primium from "../components/DashBoard.js/Primium";

const Dashboard = () => {
  const { auth } = useAuth();
  let userIdfromdb = auth?.user?.[0]?.id_user;
  return (
    <div className={styles.dashbaordMain}> 
       <HelmetConfig
          title="MyFuse.in-Dashboard"
          description="Create and enhance your professional resume with AI assistance on MyFuse.in. Our platform offers personalized resume building, job matching, and career guidance to help you land your dream job. Explore opportunities across various industries and locations, and take the next step in your career journey with MyFuse.in."
          keywords=" Job Seeker Dashboard, User Dashboard, Myfuse Dashboard, Account Dashboard, Personal Dashboard,
          How to Use Myfuse Dashboard, Managing Job Applications on Myfuse, Personal Dashboard for Job Seekers,
          Best Practices for Using Myfuse Dashboard, Customizing Your Myfuse Dashboard,
          Dashboard, User Panel, Account Management, Dashboard Tools, Online Dashboard,
          Dashboard Features, User Interface, Job Application Management, User Experience, Online Tools for Job Seekers,
          How to Track Job Applications, Managing Job Seeker Profile, Job Search Analytics Dashboard,
          Myfuse Dashboard Features, How to Optimize Your Myfuse Dashboard,
          How to Manage Job Alerts on My Fuse, Customizing Job Alerts in Dashboard,
          How to Track Job Applications Efficiently, Myfuse Dashboard for Recruiters,
          How to Use Dashboard Analytics for Job Search,
          Dashboard Insights, User Management Tools, Job Tracking System, Online Job Search Tools, Myfuse Dashboard Tips"
          canonical="https://myfuse.in/dashboard"
        />
      <AboveNavbar/>
      <MyResponsiveNavbar />  
      <div className={styles.upperwaladasj}>
          <div className={styles.firstwala}><LeftWala/></div>
          <div className={styles.middlewala} >
              <Heading/>
              <JobsByRole />
              <UseOurAI/>
               <RecomandedJobs userIdfromdb={userIdfromdb}/>
               <Primium/>
              <Compnay/>
          </div>
          <div className={styles.firstwala}><RightWala/></div>
      </div> 
      <Footer /> 
      <BottomBar/>
    </div>
  );
};

export default Dashboard;
