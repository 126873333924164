import React, { useEffect } from 'react'
import MyResponsiveNavbar from '../Navbar'
import axios from 'axios';
import { useJobs } from '../../ResumeBuilder/builder/components/context/Jobs';
import styles from "../../assets/Success.module.css"
import { useNavigate } from 'react-router-dom';
const Success = () => { 

   let {setPayment,payment,} = useJobs();  
   const Navigate = useNavigate(); 
     const getData = async() =>{
        try {
          const storedItem = JSON.parse(localStorage.getItem('auth'));
          const response = await axios.post(`${process.env.REACT_APP_BASE_BACKEND_URL}/api/checkprimium`, {
             id:storedItem.user[0]?.id_user
          });  
          setPayment({
            paymentStart:response?.data?.user[0].date_joined ,
            paymentEnd :response?.data?.user[0].date_end,
            paymentStatus:response?.data?.user[0].status ,
            paymentAmount:response?.data?.user[0].amount,
            produceName:response?.data?.user[0].product_name
    
        })
        } catch (error) {
          console.log(error)
        }
     }

  useEffect(() => {
    
   getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(payment); 
  return (
    <>
        <MyResponsiveNavbar/> 
        <div className={styles.main}>
        <div className={styles.dldo}>
           Payment Successful<img src='https://i.pinimg.com/originals/74/2f/7e/742f7ea29cbfd7fd3f4848f17e621070.gif' className='img-fluid' style={{maxHeight:"4rem" , maxWidth:"4rem"}} alt='s' />
        </div>
         <img src='https://cdn.dribbble.com/users/614270/screenshots/14575431/media/4907a0869e9ed2ac4e2d1c2beaf9f012.gif' className='img-fluid' style={{maxHeight:"18rem"}} alt='s' />
       
        <div className={styles.ddl} onClick={()=>Navigate('/welcome/dashboard')}>
            Explore Primium Content
        </div>
          </div>

        </>
  )
}

export default Success