import React, { useState } from 'react'
import styles from "../../assets/Right.module.css"
import {  PenIcon } from 'lucide-react';
import RightWalaKaOpening from './RightWalaKaOpening';
const RightWala = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
};
  return ( 
     
          <div className={styles.main}> 
                <div className={styles.submian}>
                  <div className={styles.imagesection} >
                      <img src='https://www.propeers.in/_next/image?url=%2Fimages%2Fseek-guidance-illustration.png&w=640&q=75' className='img-fluid' alt='dd' />
                      {/* <p className={styles.downchalhat}>Wondering how to upgrade your job? Ready to take the next step?</p> */}
                  </div>
                  <div className={styles.recomandateionMain}>
                      <div className={styles.heading}>Customize your preferences to receive job opportunities tailored to your skills and career goals.</div> 
                      <div className={styles.inner}>
                          <div className={styles.innerheading}>  Looking For <PenIcon size={13} color={"blue"} style={{cursor:"pointer"}} onClick={toggleOpen}/></div>    
                              <span className={styles.innertype}> 
                                internship
                              </span>
                              <span className={styles.innertype}> 
                                Jobs
                              </span>
                      </div>
                      <div className={styles.inner}>
                          <div className={styles.innerheading}>  Prefered Job Role <PenIcon size={13} color={"blue"} style={{cursor:"pointer"}} onClick={toggleOpen} /></div>    
                               
                              <span className={styles.addButton}onClick={toggleOpen}>Add Role</span>
                              
                      </div>
                      <div className={styles.inner}>
                          <div className={styles.innerheading} >  Prefered Location <PenIcon size={13} color={"blue"} style={{cursor:"pointer"}} onClick={toggleOpen} /></div>    
                              
                              <span className={styles.addButton} onClick={toggleOpen}>Add Location</span>
                              
                      </div>
                      <div className={styles.inner}>
                          <div className={styles.innerheading}>  Prefered Salary <PenIcon size={13} color={"blue"} style={{cursor:"pointer"}} onClick={toggleOpen} /></div>    
                               
                              <span className={styles.addButton} onClick={toggleOpen}>Add Salary</span>
                              
                      </div>
                        
                  </div> 
                    
                </div>
            <RightWalaKaOpening  toggleOpen={toggleOpen} isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
           
     
  )
}

export default RightWala