import React from 'react'
import styles from "../../assets/Design.module.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CircleHelp } from 'lucide-react';
import { useResume } from './components/context/Resume';

const Design = () => {

    const {
        selectedTemplate,
        templates,
        handleTemplateChange,
      } = useResume();
   

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className= {styles.custom}>
     This section ensures your resume is concise, impactful, and to the point. We focus on highlighting quantifiable achievements that demonstrate your value. Remember, your resume should be a highlight reel, not a detailed biography <br/> <br/> Keep the content clear and engaging; hiring managers want to see what you've accomplished, not wade through fluff. Aim to impress with strong, measurable results and keep it short—no one wants to read a novel about your work history!  </Tooltip>
      );


  return (
    <div className={styles.main}>
      <div className={styles.header}>
        
        
        Desgins 
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className={styles.do}><CircleHelp size={18} /></span>
        </OverlayTrigger>  



        <div className={styles.dor}></div>
      </div>      
      <div className={styles.dldo}>
      {Object.values(templates).map((template, index) => {
                return (
                  <div   key={index} onClick={() => handleTemplateChange(template.key)} className={` ${styles.dooo}  ${template.key === selectedTemplate.key ? styles.selectedTemplate : ''}`}  > 
                      <img className={styles.it} src={template.preview} alt={template.name} /> 
                       
                  </div>
                );
              })}
      </div>
    </div>
  )
}

export default Design