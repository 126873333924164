import React, { useEffect, useRef, useState } from "react";
import styles from "./Preview.module.css";
import { useResume } from "../context/Resume";
import { htmlToCanvasImage } from "../../../utils/html_to_canvas_image";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import RiseLoader from "react-spinners/RiseLoader";
import { CircleCheck, ToggleLeft, ToggleRight } from "lucide-react";
import { useJobs } from "../context/Jobs";
const Preview = () => {
  const {
    values,
    printContainerRef,
    loading,
    handleTemplateChange,
    templates,
    selectedTemplate,
  } = useResume(); 
 
  const {payment,previewResume} = useJobs(); 
  const previewRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageTransformsY, setImageTransformsY] = useState([]);
  const [selectedImageTransformY, setSelectedImageTransformY] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [cloading, setcLoading] = useState(true);
  const [orginal, setOriginal] = useState(true);
  const getA4Images = () => {
    const newImage = new Image();
    newImage.src = image;
  
    newImage.onload = () => {
      const width = newImage.width;
      const height = newImage.height;
      const a4aspectRatio = 210 / 297;
      const a4Height = width / a4aspectRatio;
      let h = height;
      const transformsY = [];
      let transformY = 0;
  
      // Calculate the transformations for A4 pages
      while (h > 0) {
        transformsY.push(transformY);
        transformY += a4Height;
        h -= a4Height;
      }
      setImageTransformsY(transformsY);
    };
  };
  
 // new one 
 useEffect(() => { 

  const timeoutId = setTimeout(() => {

    if (values) {
      if (printContainerRef.current) {
        htmlToCanvasImage(printContainerRef.current , payment).then((img) => {
          setImage(img);
          getA4Images();
        });
      }
    }

  }, 1000); // 10 seconds timeout
  // Cleanup function to clear timeout if dependencies change 
 // eslint-disable-next-line react-hooks/exhaustive-deps
  return () => clearTimeout(timeoutId); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [values, handleTemplateChange]);

  const handleImageLoad = () => {
    setcLoading(false);
    setIsImageLoaded(true);
  };

  return (
    <div className={styles.preview}>
      {/* <PreviewOptions preview={data?.preview} /> */}

      <div className={styles.dots}>
        {imageTransformsY &&
          imageTransformsY.map((_, index) => (
            <span
              key={index}
              className={
                styles.dot +
                (index === selectedImageTransformY ? " " + styles.selected : "")
              }
              onClick={() => setSelectedImageTransformY(index)}
            >
              {" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#2dc08d" }}
              />{" "}
              {selectedImageTransformY}{" "}
            </span>
          ))}
      </div>

      <div className={styles.dots}>
        {imageTransformsY &&
          imageTransformsY.map((_, index) => (
            <span
              key={index}
              className={
                styles.dot +
                (index === selectedImageTransformY ? " " + styles.selected : "")
              }
              onClick={() => setSelectedImageTransformY(index)}
            >
              {index > 0 && (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: "#2dc08d" }}
                />
              )}
              {index}
              {index < imageTransformsY.length - 1 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: "#2dc08d" }}
                />
              )}
            </span>
          ))}
      </div>

      <div className={styles.sfs}>
        <span className={styles.saved_state}>
          {loading ? (
            <span style={{ color: "black" }}>
              <ClipLoader color="#36d7b7" size={16} /> Saving...
            </span>
          ) : (
            <span style={{ color: "rgb(119 131 143" }}>
              <CircleCheck style={{ color: "rgb(0 110 220 )" }} size={15} />{" "}
              {"   "}Saved less than a minute ago
            </span>
          )}
        </span>

        <span onClick={() => setOriginal(!orginal)}>
          {orginal ? (
            <span>
              Sample <ToggleLeft size={25} />{" "}
            </span>
          ) : (
            <span>
              {" "}
              Sample <ToggleRight color={"rgb(0 110 220 )"} size={25} />{" "}
            </span>
          )}
        </span>
      </div>
      <div ref={previewRef} className={styles.preview_image}>
        {cloading && (
          <div
            className=" d-flex justify-content-center align-items-center flex-column "
            style={{ height: "100vh" }}
          >
            <h1
              style={{
                fontSize: "25px",
                fontWeight: "700",
                letterSpacing: "0.8px",
                color: "black",
              }}
            >
              MyFuse<span style={{ color: "#f96f59" }}>.</span>In
            </h1>
            <span className="m-2 text-center" style={{ color: "black" }}>
              Your resume is on the way, just adding the final sprinkles!
            </span>
            <RiseLoader color="#EEA941" style={{ marginTop: "1rem" }} />
          </div>
        )}
        {image && (
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={orginal ? image :  previewResume  || templates[selectedTemplate?.key].preview}
              alt="Resume"
              onLoad={handleImageLoad}
              className={isImageLoaded ? styles.imageLoaded : ""}
              style={{
                transform: `translateY(-${
                  imageTransformsY[selectedImageTransformY] *
                  previewRef.current.offsetHeight
                }px)`,
              }}
            />
           
          </div>
        )}
      </div>
    </div>
  );
};

export default Preview;
