import React, { useState } from "react";
import styles from "./Config.module.css";
import { ArrowDownToLine, LayoutList } from "lucide-react";
import { useResume } from "../../context/Resume";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from "file-saver";
import { htmlToCanvasImage } from "../../../../utils/html_to_canvas_image";
import Model from "../../../../../components/Model";
import { useJobs } from "../../context/Jobs";
import { useNavigate } from "react-router-dom";
const Button = () => {
  const [activeDownload, setActiveDownload] = useState(false); 
  const {payment} = useJobs();
  const {values,printContainerRef} = useResume();
  const Navigate = useNavigate(); 
  const [modalShow, setModalShow] = useState(false);
  const handleDownladImage = async() =>{
  const imageBlob = await htmlToCanvasImage(printContainerRef.current);
  saveAs(imageBlob, `${values.name}.png`);
   } 

  const handleGeneratePdf = () => {
    html2canvas(printContainerRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('download.pdf');
    });
  };

   
  return (
    <div className={styles.mobilecontainer}>
      <div>
        <div className={styles.dlo} onClick={()=>Navigate('/welcome/chooseTemplate')}>
          <LayoutList size={15} /> Designs
        </div>
      </div>

      <div className={styles.downloadConatiner}>
        <div
          className={styles.dlo}
          onClick={() => setActiveDownload(!activeDownload)}
        >
          <ArrowDownToLine size={15} /> Download{" "}
        </div>
        {activeDownload && (
          <div className={styles.underconatiner}>
            <div className={styles.butondonw} onClick={payment?.paymentStatus !== "success" ? () => setModalShow(true) : handleGeneratePdf} >Download Pdf</div>
            <div className={styles.butondonw}  onClick={
                  payment?.paymentStatus !== "success"
                    ? () => setModalShow(true)
                    : handleDownladImage
                }>Download JPG</div>
          </div>
        )}
      </div>
      <Model modalShow={modalShow} setModalShow={setModalShow}  />
    </div>
  );
};

export default Button;
