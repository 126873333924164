import React, { useEffect } from "react";
import styles from "./ResumeBuilder.module.css";
import Preview from "./components/Preview/Preview";
import previewStyles from "./components/Preview/Preview.module.css";
// import PreviewOptions from "./components/PreviewOptions/PreviewOptions";
import DocumentMeta from "react-document-meta";
import { useResume } from "./components/context/Resume";
import ChooseTemplate from "./components/templates/ChooseTemplate/ChooseTemplate";
import MyResponsiveNavbar from "../../pages/Navbar";
import { useAuth } from "./components/context/auth";
import { useJobs } from "./components/context/Jobs";
import socket from "../../components/Socket";
import RightTop from "./RightTop";
import Analysis from "./Analysis";
import Matching from "./Matching";
import Design from "./Design";
import Jobs from "./Jobs";
import { useNavigate } from "react-router-dom";

const ResumeBuilder = () => {
  const { selectedTemplate, printContainerRef, values } = useResume();
  const { activeResume  } = useJobs();
  const { auth } = useAuth();
  const navigate = useNavigate(); 
  const Build = selectedTemplate?.build;
  const Resume = selectedTemplate?.resume;
  const meta = {
    title: " MyFuse Resume Builder",
    description: "Resume Builder",
  };

  useEffect(() => {
    socket.emit("edit", {
      userId: auth?.user[0]?.id_user,
      values: values,
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(()=>{
      if(!selectedTemplate) {
        navigate("/resume-examples")
      }
  },[selectedTemplate])
 

 

  return (
    <DocumentMeta {...meta}>
      <MyResponsiveNavbar />
      {/* For only print purposes */}
      <div className={previewStyles.print_only_resume_page} id="print_content">
        <div
          ref={printContainerRef}
          className={previewStyles.container}
          style={{
            width: "100%",
            padding: selectedTemplate?.page_margins,
          }}
        >
          {Resume && <Resume sections={values.sections} />}
        </div>
      </div>
      <div className={styles.topbar}>
        <RightTop />
      </div>

      {selectedTemplate ? (
        <div className={styles.container}>
          {activeResume === 4 ? (
            <div className={styles.left}>
              <Design />
            </div>
          ) : (
            <div className={styles.left}>
              <Build />
            </div>
          )}

          {activeResume === 1 && (
            <div className={styles.rightt}>
              <Preview template={selectedTemplate} />
            </div>
          )}

          {activeResume === 2 && (
            <div className={styles.rightana}>
              <Analysis />
            </div>
          )}
          {activeResume === 3 && (
            <div className={styles.rightana}>
              <Matching />
            </div>
          )}
          {activeResume === 4 && (
            <div className={styles.rightt}>
              <Preview template={selectedTemplate} />
            </div>
          )}

           {activeResume === 5 && (
            <div className={styles.rightt}>
               <Jobs/>
            </div>
          )}
        </div>
      ) : (
        <ChooseTemplate />
      )}
      {/* <BottomBar /> */}
    </DocumentMeta>
  );
};

export default ResumeBuilder;
