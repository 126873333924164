import React, { useEffect, useState } from 'react'
import styles from "../../assets/LeftWala.module.css"
import { useAuth } from '../../ResumeBuilder/builder/components/context/auth';
import { useNavigate } from 'react-router-dom';
import userIcon from "../../assets/userIcon.webp"
const LeftWala = () => {
    const { auth } = useAuth(); 
    const navigate = useNavigate();
    const [imgSrc, setImgSrc] = useState(
        userIcon
      );
    useEffect(() => {
        const storedAuth = JSON.parse(localStorage.getItem("auth"));
        if (storedAuth && storedAuth.pic) {
          setImgSrc(storedAuth.pic);
        }
      }, []);
  return (
    <div className={styles.main}>
        <div className={styles.mainsim}>
               <div className={styles.imageSection}>
                   <img src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images :imgSrc} alt='sfas'  />
                   <div className={styles.wave2}></div>
               </div>

               <div className={styles.textsecion}>
                   <span className={styles.nameofmobile}>{auth?.user?.[0]?.firstname}  {auth?.user?.[0]?.lastname}  </span>  
                   <span className={styles.email}> {auth?.user?.[0]?.qualification} {auth?.user?.[0]?.stream}</span>  
                   <span className={styles.email}> {auth?.user?.[0]?.college ? (` @ ${auth?.user?.[0]?.college} `) :""}</span>  
                   <span  onClick={()=>(navigate("/welcome/account"))} className={styles.viewandupdate}> Update Profile</span>   
               </div>
             </div>
             <div className={styles.mainsims}>
               <div className={styles.hen}>
                <div className={styles.profile}>Profile Status</div>
                <div className={styles.topw}>
                        <div className={styles.applied}>
                            <span className={styles.textcd}>Applied</span>  
                            <span className={styles.textcddown}>0</span>
                        </div>
                        <div className={styles.applied}>
                            <span className={styles.textcd}>Active</span> 
                            <span className={styles.textcddown}>0</span>
                        </div> 
                 </div>
               </div> 
               
             </div>
    </div>
  )
}

export default LeftWala