import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../../assets/Job.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const RecomandedJobs = () => {
  const resume = JSON.parse(localStorage.getItem("resume"));
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills;
  const Navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [skillfromdbapi, setskillfromdibapi] = useState();
  const [loading, setLoadingJobs] = useState(false);
  const skill_from_resume = role?.join(",");
  const getUserSkill = async () => {
    const datalocal = JSON.parse(localStorage.getItem("auth"));

    if (!datalocal?.token) {
      console.error("No authentication token found in local storage");
      return;
    }

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/skillsbyid`,
        {
          headers: {
            Authorization: `Bearer ${datalocal.token}`,
          },
        }
      );

      if (data?.skillsfromdb?.length > 0) {
        setskillfromdibapi(data.skillsfromdb[0].skills);
      } else {
        console.warn("No skills found in the response");
      }
    } catch (error) {
      console.error("Error fetching user skills:", error);
    }
  };

  const mergedskill = `${skill_from_resume},${skillfromdbapi}`;

  const getAllJobs = async () => {
    try {
      setLoadingJobs(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=${mergedskill}`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      if (data?.success) {
        const jobs = data;
        jobs.jobsWithPercentage.sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(jobs.jobsWithPercentage);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillfromdbapi, mergedskill]); // Call getAllJobs when skillfromdbapi is updated

  const handleNavigate = (id) => {
    Navigate(`/jobs/id=${id}`);
  };
  return (
    <>
      <div className={styles.recomandedMain}>
        <div>
          <h1 className={styles.chalhat}>Job Recommendation</h1>
          <p className={styles.paradd}>
            As Per Your Preference: Join us and take advantage of our wellness
            programs!
          </p>
        </div>

        <Swiper
          spaceBetween={10}
          centeredSlides={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2.3,
            },
            1024: {
              slidesPerView: 2.3,
            },
          }}
        >
          {jobs?.map((job) => (
            <SwiperSlide
              key={job.id_jobpost}
              className="mb-5"
              style={{
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                padding: "20px",
              }}
            >
              <div
                onClick={() => handleNavigate(job.id_jobpost)}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div style={{ flex: 1 }}>
                    <h5
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {job.jobtitle.length > 20
                        ? `${job.jobtitle.substring(0, 20)}...`
                        : job.jobtitle}
                    </h5>
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#777",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {job.companyName.length > 20
                        ? `${job.companyName.substring(0, 20)}...`
                        : job.companyName}
                    </p>
                  </div>
                  <div>
                    <img
                      className="img-fluid"
                      src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                      alt="Company Logo"
                      style={{
                        height: "40px",
                        width: "60px",
                        objectFit: "contain",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #EEE",
                    marginBottom: "1rem",
                  }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <p
                        className="card-text"
                        style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          style={{ marginRight: ".5rem", color: "#739ee8" }}
                        />
                        {job.qualification.length > 7
                          ? `${job.qualification.substring(0, 7)}...`
                          : job.qualification}
                      </p>
                      <p
                        className="card-text"
                        style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ marginRight: ".5rem", color: "#739ee8" }}
                        />
                        {job.location.length > 10
                          ? `${job.location.substring(0, 10)}...`
                          : job.location}
                      </p>
                    </div>
                    <div className="col-6">
                      <p
                        className="card-text"
                        style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faMoneyCheckDollar}
                          style={{ marginRight: ".5rem", color: "#739ee8" }}
                        />
                        {job.minimumsalary.length > 10
                          ? `${job.minimumsalary.substring(0, 10)}...`
                          : job.minimumsalary}
                      </p>
                      <p
                        className="card-text"
                        style={{ fontSize: "12px", marginBottom: "0.5rem" }}
                      >
                        <FontAwesomeIcon
                          icon={faUserTie}
                          style={{ marginRight: ".5rem", color: "#739ee8" }}
                        />
                        {job.experience}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default RecomandedJobs;
