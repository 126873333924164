import React, { useState } from "react";
import styles from "../../assets/HomePage/RightWalaKaOpening.module.css";
import { Plus, Save, X } from "lucide-react";
const RightWalaKaOpening = ({ isOpen,  toggleOpen }) => {
  const [Lokingfor, setLokingfor] = useState([]);
  const [jobrole , setJobrole] = useState('');  
  const [joblocation , setjobLocation] = useState('');  
  const [preferedJobRole,setPreferredJobRole] = useState([]); 
  const [preferedJoblocation,setPreferredJobLocation] = useState([]); 


   const handlePreferedJobRole = () =>{
       setPreferredJobRole((prev)=>{
         if(preferedJobRole.includes(jobrole)) {
          return prev.filter((item)=> item !==jobrole); 
         }
         else {
          return [...prev,jobrole];
         }
 
       }) 
       setJobrole(''); 
   }

   const handlePreferedJobRoleDelete = (ele) =>{
    setPreferredJobRole((prev)=>{
      if(preferedJobRole.includes(ele)) {
       return prev.filter((item)=> item !==ele); 
      }
      else {
       return [...prev,ele];
      }

    }) 

    

    
} 


const handleJobLocation = () =>{
   setPreferredJobLocation((prev)=>{
    if(preferedJoblocation.includes(joblocation)) {
      return prev.filter((item)=> item !==joblocation);
      }
      else {
        return [...prev,joblocation];
        }
   })
     setjobLocation('');
}

const handleJobLocationDelete = (ele) =>{
   setPreferredJobLocation((prev)=>{
    if(preferedJoblocation.includes(ele)) {
      return prev.filter((item)=> item !==ele);
      }
      
       else {
        return [...prev,ele];
        }
        })
}



  const handleLoking = (value) => {
    setLokingfor((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  // Log the updated state value using a useEffect hook
  // useEffect(() => {
  //   console.log(preferedJobRole);
  // }, [preferedJobRole]);

  return (
    <div className={`${styles.main} ${isOpen ? styles.active : ""}`}>
      <div className={styles.ineeermain}>
        <div className={styles.heading}>
          <h1 className={styles.headingText}>Manage your work preferences</h1>
          <p className={styles.paratext}>
            Set your work preferences and we'll match you with the right jobs
          </p>
        </div>

        <div className={styles.betweencontainer}>
          {/* // looking for  */}
          <div className={styles.lookingforcontainer}>
            <div className={styles.lookingfor}>
              <h1 className={styles.lookingforText}>Looking for</h1>
            </div>
            <div>
              {Lokingfor.includes("Internship") ? (
                <span
                  className={styles.innertype}
                  onClick={() => handleLoking("Internship")} 
                  style={{border:'1px solid rgb(9, 73, 163)'}}
                >
                  internship{" "}
                  <X
                    size={15}
                    color="rgb(9, 73, 163
                                        )"
                  />
                </span>
              ) : (
                <span
                  className={styles.innertype}
                  onClick={() => handleLoking("Internship")}
                >
                  internship <Plus size={15}/>
                </span>
              )}

              {Lokingfor.includes("jobs") ? (
                <span
                  className={styles.innertype}
                  style={{border:'1px solid rgb(9, 73, 163)'}}
                  onClick={() => handleLoking("jobs")}
                >
                 Full Time jobs{" "} 
                  <X
                    size={15}
                    color="rgb(9, 73, 163
                                        )"
                  />
                </span>
              ) : (
                <span
                  className={styles.innertype}
                  onClick={() => handleLoking("jobs")}
                >
                                   Full Time jobs <Plus size={15}/>
                </span>
              )}
            </div>
          </div>
          {/* // prefered job roles */}
          <div className={styles.lookingforcontainer}>
            <div className={styles.lookingfor}>
              <h1 className={styles.lookingforText}>Prefered Job Role</h1> 

              {/* //input for the prefered job roles */}
              <div className={styles.inputcontainer}>
                    <input
                    type="text"
                    className={styles.input}
                    placeholder="Enter your prefered job role"
                    value={jobrole}
                    onChange={(e) => setJobrole(e.target.value)}
                    />
                  <span className={styles.inputicon}>
                   <Plus color={"white"} className={styles.dd} onClick={handlePreferedJobRole}/>
                  </span> 
                   
                  </div> 

                  <div>
                     {preferedJobRole.map((ele,key)=>{
                        return(
                          
                             <span
                                className={styles.innertype} 
                                key={key}
                                style={{border:'1px solid rgb(9, 73, 163)'}}
                                onClick={()=>handlePreferedJobRoleDelete(ele)}
                              >
                              {ele}{" "}
                            <X
                              size={15}
                              color="rgb(9, 73, 163)"
                            />
                </span>
                              
                              )
                     })}
                  </div>

            </div>
            <div>
              

             
            </div>
          </div>

           {/* // prefered job location */}
           <div className={styles.lookingforcontainer}>
            <div className={styles.lookingfor}>
              <h1 className={styles.lookingforText}>Prefered Job Location</h1> 

              {/* //input for the prefered job roles */}
              <div className={styles.inputcontainer}>
                    <input
                    type="text"
                    className={styles.input}
                    placeholder="Enter your prefered job Location"
                    value={joblocation}
                    onChange={(e) => setjobLocation(e.target.value)}
                    />
                  <span className={styles.inputicon}>
                   <Plus color={"white"} className={styles.dd} onClick={handleJobLocation}/>
                  </span> 
                   
                  </div> 

                  <div>
                     {preferedJoblocation.map((ele,key)=>{
                        return(
                          
                             <span
                                className={styles.innertype} 
                                key={key}
                                style={{border:'1px solid rgb(9, 73, 163)'}}
                                onClick={()=>handleJobLocationDelete(ele)}
                              >
                              {ele}{" "}
                            <X
                              size={15}
                              color="rgb(9, 73, 163)"
                            />
                </span>
                              
                              )
                     })}
                  </div>

            </div>
            <div>
              

             
            </div>
          </div>
          
        </div>
        <div className={styles.buttoncontainer}>
          <span onClick={toggleOpen} className={styles.close}>
            Close <X size={15} />{" "}
          </span>
           
          <span className={styles.save}>
            Save <Save size={15} />{" "}
          </span>
        </div>
      </div>
    </div>
  );
};



 

 

 

export default RightWalaKaOpening;
