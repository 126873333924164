import React from 'react'
import styles from "../../assets/Job.module.css";
import { Award, ChevronRight, PenSquareIcon, WalletCards } from 'lucide-react';
const Primium = () => {
  return (
    <div className={styles.primiumMain}>
         <div className={styles.primiumMainsub}>
            <div className={styles.PrimiumImage}>
               <img src='https://cdn-icons-png.freepik.com/512/7439/7439918.png' alt='d' className={styles.imagepremium} />
            </div>
            <div className={styles.premiumcontenn}>
                  <span className={styles.chalhat} style={{textAlign:'center'}}>
                  Accelerate your job search with premium services 
                  </span>
                  <span className={styles.downchalhat2}>
                  Land your dream job in no time: we'll pimp your CV, charm those recruiters, uncover the best gigs, and throw in a few laughs along the way!
                  </span>
                  <div className={styles.primumbuttoncont}>
                     <span className={styles.premumbutton}> <PenSquareIcon size={15 }  color={"rgb(160, 102, 255) "} style={{marginRight:"10px"}} /> AI Resume <ChevronRight size={15} style={{marginLeft:"10px"}} /> </span>
                     <span className={styles.premumbutton}> <WalletCards size={15 } color={"rgb(160, 102, 255) "} style={{marginRight:"10px"}} /> Digital Card <ChevronRight size={15} style={{marginLeft:"10px"}} /></span>
                     <span className={styles.premumbutton}> <Award size={15 } color={"rgb(160, 102, 255) "} style={{marginRight:"10px"}} /> Priority Applicant <ChevronRight size={15} style={{marginLeft:"10px"}} /></span>
                  </div>
            </div>
         </div>
  </div>
  )
}

export default Primium