import React, { useState } from 'react'
import styles from './assets/Forgot.module.css'
import axios from 'axios'; 
import HashLoader from "react-spinners/HashLoader";
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-hot-toast'
const ForgotPassword = () => { 
     const [value ,setValue] = useState('');  
     let [loading, setLoading] = useState(false);
     const Navigate = useNavigate(); 
     const  handleSubmit =  async() =>{ 
      try { 
         setLoading(true);

         const resposnse = await axios.post (`${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/forgot`,
         {email:value},
         {
            headers: {
              Authorization: "Bearer madebyashutosh",
            },
          }
          
         );
          
          if(resposnse?.data?.success) {
            toast.success(resposnse.data.message);
            Navigate( `/forgot-verify/${value}`);
          } 
          else {
            toast.error(resposnse.data.message);
            }
         setLoading(false);
      } catch (error) {
         // console.log(error); 
         toast.error('Please Register First!!!');
         setLoading(false);
      }
   
   }
   
  return (
    <div className={`container-fluid ${styles.main} `} >
        <div className={`row ${styles.container}`} >
             <div className={`col-11 col-md-4 ${styles.box}`}  >
                <h1 className={`${styles.title}`}>Forgot Password ?</h1>
                <div className={`${styles.para}`}>
                Type in your email and get ready to bid farewell to your old password. We're about to give it a stylish upgrade! 
                </div>
                <input  value={value} placeholder='Email'  className={`${styles.button}`} onChange={(e)=>{setValue(e.target.value)}} required />
                {/* <button className={styles.btn} onClick={handleSubmit} >Recover </button> */}
                {loading ? (
                  <div className="text-center d-flex justify-content-center">
                    {" "}
                    <div
                      className={` d-flex  justify-content-center ${styles.btn}`}
                    >
                      <HashLoader color="#000000" size={25} />
                    </div>
                  </div>
                ) : (
                  <div className="text-center ">
                    {" "}
                    <button type="submit" className={`${styles.btn}`}  onClick={handleSubmit}>
                    Recover
                    </button>
                  </div>
                )}
                <p className={styles.down}>Haven’t got an account? <span className={styles.signup} onClick={()=>Navigate("/signup")}>Sign Up</span></p>
             </div>
            
        </div>    
    </div>
  )
}

export default ForgotPassword