import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styles from "../../assets/JobResume.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CircleHelp } from 'lucide-react';
const Jobs = () => { 
    const [jobs, setJobs] = useState([]);
    const getAllJobs = async () => {
        try {
         
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/filterjob?skills=software`,
            {
              headers: {
                Authorization: "Bearer madebyashutosh",
              },
            }
          );
    
          if (data?.success) {
            const jobs = data;
            setJobs(jobs.jobsWithPercentage);
          }
           
        } catch (error) {
          console.log(error);
        }
      };

      useEffect(()=>{
        getAllJobs();  
      },[])


      const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className={styles.custom}>
            This section ensures your resume content matches the job details effectively.
            <br /><br />
            Focus on aligning your skills, experiences, and achievements with the job requirements.
            Highlight relevant keywords and tailor your resume to showcase how your background
            fits the role. Demonstrate that you understand the job’s needs and how you can
            contribute to the company’s success.
        </Tooltip>
      );
 

      const handleNavigate = (id) => {
        window.open(`/jobs/id=${id}`, '_blank');
      };

  return (
    <div className={styles.main}> 
     <div className={styles.header}>
        Jobs 
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className={styles.do}><CircleHelp size={18} /></span>
        </OverlayTrigger> 
        <div className={styles.dor}></div>
      </div>  
         {jobs.map((ele,index )=>{
            return (
                <div className={styles.card} key={index}>
                   
                   
                   <div className="d-flex justify-content-between" onClick={() => handleNavigate(ele.id_jobpost)}>
                        <div>
                          <h3 className={styles.hddh}> {ele.jobtitle} </h3>
                          <h5 className={styles.down}>
                            {" "}
                            {ele.companyName} | {ele.job_nature}{" "}
                          </h5>
                        </div>

                        <div>
                          <img
                            src={`https://old.myfuse.in/uploads/logo/${ele.companyLogo}`}
                            style={{ width: "5rem" }}
                            className="img-fluid"
                            alt="i"
                          />
                        </div>
                      </div>
                    {/* this  skill section end */}
                    <div className="container-fluid" onClick={() => handleNavigate(ele.id_jobpost)}>
                        <div className="row">
                          <div className="col-12 col-md-12 mt-2">
                            <div className="container">
                              <div className="row">
                                <div
                                  className="col-6 col-md-6"
                                  style={{ padding: "0px" }}
                                >
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faBriefcase}
                                      style={{
                                        marginRight: ".5rem",
                                        color: "#739ee8",
                                      }}
                                    />
                                    {ele.qualification.length > 10
                                      ? `${ele.qualification.substring(
                                          0,
                                          10
                                        )}...`
                                      : ele.qualification}
                                  </p>
                                  <p
                                    className={styles.pard}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      style={{
                                        marginRight: ".5rem",
                                        color: "#739ee8",
                                      }}
                                    />{" "}
                                    {ele.location.length > 10
                                      ? `${ele.location.substring(0, 10)}...`
                                      : ele.location}
                                  </p>
                                </div>
                                <div
                                  className={`col-6 col-md-6 ${styles.parad}`}
                                >
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faMoneyCheckDollar}
                                      style={{
                                        marginRight: ".5rem",
                                        color: "#739ee8",
                                      }}
                                    />
                                    {ele.minimumsalary.length > 20
                                      ? `${ele.minimumsalary.substring(
                                          0,
                                          20
                                        )}...`
                                      : ele.minimumsalary}
                                  </p>
                                  <p className={styles.pard}>
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faUserTie}
                                      style={{
                                        marginRight: ".5rem",
                                        color: "#739ee8",
                                      }}
                                    />{" "}
                                    {ele.experience}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  
                </div>
            )
         })}
    </div>
  )
}

export default Jobs