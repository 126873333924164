import React, { useState } from 'react'
import MyResponsiveNavbar from './Navbar' 
import styles from "../assets/Blog/SingleBlog.module.css"
import Footer from './Footer'
import { useParams } from 'react-router-dom'
import axios from "axios";
import { CalendarClock, TimerReset } from 'lucide-react'
import HelmetConfig from '../components/HelmetConfig'

const SingleBlog = () => {
    const { slug } = useParams();
    const [Blog,setBlog] = useState(''); 
    
    const parts = slug.split('-');
    const id = parts.pop(); // Last part
    const rest = parts.join('-'); // Everything before the last hyphen
    
    const getBlogDetails = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/singleblogs?id_blogs=${id}`);

            console.log(data?.blog[0])
            await setBlog(data?.blog[0]);
            window.scrollTo(0, 0); // Scroll to top when component renders
      } catch (error) {
        console.log(error);
      }
    };
  
    const formatDate = (timeStamp) => {
      const date = new Date(timeStamp);
      const options = { year: 'numeric', month: 'long' };
      return date.toLocaleDateString('en-US', options);
    };
    
    const formattedDate = formatDate(Blog.time_stamp); // "July, 2023"

    useState(()=>{
     getBlogDetails(); 
      
    },[])
  
   

  return (
    <>
     <HelmetConfig
          title={rest}
          description={Blog.short_desc}
          keywords={Blog.blog_tag}
          
        />
    <MyResponsiveNavbar/>
         <div className={styles.main}> 
          
          <div>
             <img src={Blog.img_link} className={`img-fluid ${styles.img}`} alt='images' />
          </div>

          
            <div className={styles.blogDetails}>
                   <div className={styles.tagcontainer}>
                   {Blog?.blog_tag?.split(',').map((individualTag, index) => (
                    <span key={index} className={styles.tagItem}>
                    {individualTag.trim()}
                    </span>
                ))}
                   </div>
                  <div > 
                       <h1 className={styles.heaing}>{Blog.blog_heading}</h1>
                  </div> 

                   <div className={styles.personeConteinr}>
                          <div>  <img className={styles.image}   src= "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiMxOKqtdSW8ybpkCH_mVD7dmsIv5z03gaHg&s" alt='imge' /> MyFuse.In </div>
                          <div>  <CalendarClock size={12} /> {formattedDate} </div>
                          <div>  <TimerReset size={12} /> {Blog.TR} min to read </div>

                   </div>
                
                 <div className={styles.londDesc} dangerouslySetInnerHTML={{ __html: Blog.long_desc }} />
            </div>
       
         </div>
     <Footer/>
    </>
  )
}

export default SingleBlog