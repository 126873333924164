import React from "react";
import styles from "./IITEResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";

const IITEResume = ({ sections }) => {
  return (
    <div className={styles.resume}>
      <Header
        profile={sections.profile.data}
        iitb_logo={sections.settings?.data.logo}
      />
      <Education
        education={sections.education?.data}
        settings={sections.settings?.data}
      />
      
      <Experience
        experience={sections.experience?.data}
        settings={sections.settings?.data}
      />
       <TechnicalSkills
        technicalSkills={sections.technicalSkills?.data}
        settings={sections.settings?.data}
      />
       <Projects
        projects={sections.projects?.data}
        settings={sections.settings?.data}
      />
      <Achievements
        achievements={sections.achievements?.data}
        settings={sections.settings?.data}
      />
      <Awards
        awards={sections.awards?.data}
        settings={sections.settings.data}
      />
     
      <PORs
        pors={sections.positionsOfResponsibility?.data}
        settings={sections.settings?.data}
      />
     
      <ExtraCurriculars
        extraCurriculars={sections.extraCurriculars?.data}
        settings={sections.settings?.data}
      />
    </div>
  );
};

const Header = ({ profile, iitb_logo }) => {
  if (!profile) return null;
  return (
    <div className={styles.header} >
      <div className={styles.user_details}>
          <div>
                <h1 className={styles.header_name}>{profile.name}</h1>
          </div>
          <div className={styles.header_bewlowname}>
              <div className={styles.header_text}> <a href="tel:"> {profile.phone} </a>  </div>
              <div className={styles.header_text}>  {profile.mail ? "|" : '' } <a href={`mailto:${profile.mail}`}> {profile.mail} </a> </div>
              <div className={styles.header_text}>  {profile.linkedin ? "|" : '' } <a href={`https://www.linkedin.com/in/${profile.linkedin}`}> {profile.linkedin ? ` linkedin.com/in/${profile.linkedin}` :""} </a></div>
              <div className={styles.header_text}>  {profile.github ? "|" : '' }<a href={`https://github.com/${profile.github}`}> {profile.github ?  `github.com/${profile.github} `:''} </a></div>
          </div>
      </div>
    </div>
  );
};

const Section = ({ title, children}) => {
  if (!children) return null;
  return (
    <div className={styles.section}>
          <div>
      <h2 className={styles.format1}>{title}</h2>
      <div className={styles.hr}> </div>
    </div>
         
      <div className={styles.section_container}>{children}</div>
    </div>
  );
};

const Points = ({ points, years, markdown = true, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.point}>
          {markdown ? (
            <>
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i>{years[index]}</i>}
            </>
          ) : (
            <>
              <div>{point}</div>
              {years && <i>{years[index]}</i>}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience, settings }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <Section title="Experience" >
      {experience?.map((exp, index) => (
        <div className={styles.project} key={index} >
          <div className={styles.firstline}>
            <h3 className={styles.title}>{exp.role}</h3>
            <i> {exp.timePeriod}</i>
          </div>

          <div className={styles.secondline}>
           
            
            <i style={{fontWeight:'400'}}> <a href="http://">{exp.company}</a>  {exp.techstack ? "|" : '' }  {exp.techstack}   </i>
            <i> {exp.location}  {exp.type ?"(" : ""} {exp.type}  { exp.type ? ")" : ""} </i> 
          </div>
             
          <div
            style={{
              marginLeft: "1rem",
            }}
          >
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </Section>
  );
};

const Projects = ({ projects, settings }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <Section title="Projects" settings={settings}>
      {projects?.map((project, index) => (
        <div className={styles.project} key={index}>
          <div className={styles.firstline}>
            <h3 className= {styles.title}>  {project.title} </h3>
            <i>{project.year}</i>
          </div>

          <div className={styles.secondline}>
            <i  >{project.techstack}</i>
            <i>{project.organisation}</i>
          </div>

          <div
            style={{
              marginLeft: "1rem",
            }}
          >
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </Section>
  );
};

const Education = ({ education, settings }) => {
  if (!education || education.length === 0) return null;
  return (
    <Section title="Education" settings={settings}>
      
      <ul className={styles.border}>
  {education.map((point, index) => (
    <>
    <li key={index} >
      <span style={{fontWeight:'600'}}>  {point.college} </span>
      <i>{point.year}</i>
    </li>
    <div key={index} style={{ display:'flex' , justifyContent:'space-between'}}>
    <i>{point.degree} {point.branch ? `in ${point.branch}` : ''}</i>
    <i>{ `${point.cgpa} ${point.percentage}`  }</i>
  </div>
    </>
  ))}
</ul>
    </Section>
  );
};

const TechnicalSkills = ({ technicalSkills, settings }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <Section title="Technical Skills" settings={settings}>
      {technicalSkills.map((tech, index) => (
        <div key={index}>
          <strong>{tech.name}</strong>: {tech.skills?.join(",")}
        </div>
      ))}
      <div style={{ height: "0.7rem" }}></div>
    </Section>
  );
};

const ExtraCurriculars = ({ extraCurriculars, settings }) => {
  if (!extraCurriculars || extraCurriculars.length === 0) return null;
  return (
    <Section title="Extra Curriculars" settings={settings}>
      <Points
        points={extraCurriculars.map((point) => point.description)}
        years={extraCurriculars.map((point) => point.year)}
        markdown={true}
      />
    </Section>
  );
};

const Achievements = ({ achievements, settings }) => {
  if (!achievements || achievements.length === 0) return null;
  return (
    <Section title="Scholastic Achievements" settings={settings}>
      <Points
        points={achievements.map((point) => point.description)}
        years={achievements.map((point) => point.year)}
        markdown={true}
      />
    </Section>
  );
};

const Awards = ({ awards, settings }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <Section title="Scholarships and Awards" settings={settings}>
      <Points
        points={awards.map((point) => point.description)}
        years={awards.map((point) => point.year)}
        markdown={true}
      />
    </Section>
  );
};

const PORs = ({ pors, settings }) => {
  if (!pors || pors.length === 0) return null;
  return (
    <Section title="PRACTICUM" settings={settings}>
      {pors?.map((por, index) => {
        return (
          <div className={styles.project} key={index}>
            <div className={styles.firstline}>
              <h3 className={styles.title}>{por.title}</h3>
              <i>{por.year}</i>
            </div>
            <div
              style={{
                marginLeft: "1rem",
              }}
            >
              <Points points={por.points} markdown={true} secondary={true} />
            </div>
          </div>
        );
      })}
    </Section>
  );
};

export default IITEResume;
