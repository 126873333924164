
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
const Payment = ({active}) => {
    const [transactionId] = useState('TXN'+Date.now());
  const [name, SetName] = useState();
  const [product] = useState('Basic');
  const [mail, setMail] = useState('test@gmail.com');
  const [amount , setAmount] = useState(247);
  const [hash , setHash] = useState();  
  const [merchentkey ] = useState("BPjbfG"); 
   const [user_id , setUser_id] = useState(20)
   const { auth } = useAuth();
  const getHash = async (event) => {  
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_BACKEND_URL}/api/hash`, {
        name:name,
        email:mail,
        transactionId:transactionId,
        product:product,
        amount:amount,
        user_id:user_id
      }); 
      setHash(response.data.hash);  
      setTimeout(() => {
        document.getElementById('payuForm').submit();
      }, 0);
    } catch (error) {
      console.error('Error initiating payment:', error);
       
    }
  } 

  useEffect(()=>{  
      
     if(auth.token!=="") {
      setMail(auth?.user[0]?.email); 
      SetName(auth?.user[0]?.firstname); 
      setUser_id(auth?.user[0]?.id_user)
     }
   
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]) 

  useEffect(()=>{
     if(active===1){
      setAmount(99); 
      }else if(active===3){
        setAmount(247);
        }else if(active===5){
          setAmount(349);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[active])

 

  return (
    <div  >
            <form  id="payuForm" action='https://secure.payu.in/_payment' method='post' onSubmit={getHash} target="_blank" >
            <input type="hidden" name="key" value={merchentkey} />
            <input type="hidden" name="txnid" value={transactionId} />
            <input type="hidden" name="amount" value={amount} />
            <input type="hidden" name="productinfo" value={product} />
            <input type="hidden" name="firstname" value={name} />
            <input type="hidden" name="email" value={mail} />
            <input type="hidden" name="surl" value={`${process.env.REACT_APP_BASE_BACKEND_URL}/api/success`} />
            <input type="hidden" name="furl" value={`${process.env.REACT_APP_BASE_BACKEND_URL}/api/failure`} />
            <input type="hidden" name="udf1" value={user_id} />
            <input type="hidden" name="hash" value={hash} />
            <input type="submit" value="Upgrade Now" style={{background:"linear-gradient(to top left,#9c3eff,#5345ff)",color:"white" ,width:"100%" , fontWeight:'500' }}/> 
            </form> 
   </div>
  )
}

export default Payment