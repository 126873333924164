import React, { useState } from 'react'
import styles from "../../assets/Account.module.css"
import {   Github, Globe, GraduationCap, Linkedin, Mails, NotebookPen, Pen, Phone, ScrollText } from 'lucide-react'
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import {toast} from 'react-hot-toast'
import HashLoader from "react-spinners/HashLoader";
import { AIAboutMe, AIEducation, AIExperience } from './AIforAccount';
   const Right = ({ imgSrc, auth , setAuth }) => { 
   const [show, setShow] = useState(false);
   const [showPersonal, setShowPersonal] = useState(false);
   const [showAbout, setShowabout] = useState(false);
   const [showExperience, setShowExperience] = useState(false);
   const [showEducation, setShowEducation] = useState(false);
   const [selectedImage, setSelectedImage] = useState(null);
   const [imageFile, setImageFile] = useState(null);
   const [showProfileImage, setShowProfileimage] = useState(false);
   const [selectedProfileImage, setSelectedProfileImage] = useState(null);
   const [ProfileImageFiile, setProfileImageFile] = useState(null);
   const [loading,setLoading] = useState(false); 
   const [name ,setName] = useState(auth?.user?.[0]?.firstname); 
   const [lname ,setlName] = useState(auth?.user?.[0]?.lastname);
   const [desc ,setdesc] = useState(auth?.user?.[0]?.shortDescription);
   const [contact ,setcontact] = useState(auth?.user?.[0]?.contactno);
   const [location ,setlocation] = useState(auth?.user?.[0]?.city);
   const [git ,setgit] = useState(auth?.user?.[0]?.git_link);
   const [linkedin ,setlinkedin] = useState(auth?.user?.[0]?.linkedin);
   const [website ,setWebsite] = useState(auth?.user?.[0]?.other_link);
   const [college ,setcollege] = useState(auth?.user?.[0]?.college);
   const [currentCompany ,setcurrentCompany] = useState(auth?.user?.[0]?.current_company);
   const [state , setState] = useState(auth?.user?.[0]?.state);
   const [designation , setdesignation] = useState(auth?.user?.[0]?.designation);
   const [degree , setdegree] = useState(auth?.user?.[0]?.qualification);
   const [stream , setstream] = useState(auth?.user?.[0]?.stream);
 

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const handleClosePersonal = () => setShowPersonal(false);
   const handleShowPersonal = () => setShowPersonal(true);
   const handleShowProfileImage = () => setShowProfileimage(true);
   const handleCloseProfileImage = () => setShowProfileimage(false);
   const handleCloseAboutMe = () => setShowabout(false);
   const handleShowaboutme = () => setShowabout(true);
   const handleCloseExperience = () => setShowExperience(false);
   const handleShowExperience = () => setShowExperience(true);
   const handleCloseEducation = () => setShowEducation(false);
   const handleShowEducation = () => setShowEducation(true);
   const updateAuthContext = async(email) =>{
   
      try {
         const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/contextUpdate`,
            {
              email:email
            },
            {
              headers: {
                Authorization: "Bearer madebyashutosh",
              },
            },
          ); 
          setAuth({
            ...auth,
            user: data.user,
             
          });
           
      } catch (error) {
          console.log(error)
      }


   }
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const handleProfileImageChange = (event) => { 
   if (event.target.files && event.target.files[0]) {
      setSelectedProfileImage(URL.createObjectURL(event.target.files[0]));
      setProfileImageFile(event.target.files[0])
    }
  };
  const handleSaveImage = async () => { 
   if (imageFile) {
     const formData = new FormData();
     formData.append('coverImage', imageFile);
     formData.append('email' , auth?.user?.[0]?.email )
     try {
       setLoading(true)
       const response = await axios.post(
         `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/upload`,
         formData,
         {
           headers: {
             'Content-Type': 'multipart/form-data',
             Authorization: "Bearer madebyashutosh",
           },
         }
       );
       console.log('Image uploaded successfully', response.data);
       updateAuthContext(auth?.user?.[0]?.email)
       setLoading(false)
       toast.success("Cover Image Updated!!")
       // Optionally update the state or perform additional actions after successful upload
     } catch (error) {
       console.error('Error uploading image', error);
       setLoading(false)
     }
   }
   handleClose();
 };
 const handleProfileSaveImage = async() => {
   if (ProfileImageFiile) {
      const formData = new FormData();
      formData.append('profileImage', ProfileImageFiile);
      formData.append('email' , auth?.user?.[0]?.email )
      try {
        setLoading(true)
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: "Bearer madebyashutosh",
            },
          }
        );
        updateAuthContext(auth?.user?.[0]?.email)
        setLoading(false)
        toast.success("Profile Image Updated!!")
        // Optionally update the state or perform additional actions after successful upload
      } catch (error) {
        console.error('Error uploading image', error);
        setLoading(false)
      }
    }
   handleCloseProfileImage();
 }; 


 const handleSaveIntro = async() => {

  try {
    

    setLoading(true)
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
      {
        email:auth?.user?.[0]?.email,
        firstname:name,
        lastname:lname,
        shotdesc:desc,
        contactno:contact,
        recentCompany:currentCompany,
        location:location,
        github_link:git,
        linkedin_link:linkedin,
        web_link:website,
        college_name:college,
        degree:degree,
        stream:stream,
        designation:designation,
        state:state
       },
      {
        headers: {
          Authorization: "Bearer madebyashutosh",
        },
      }
    );

    
    updateAuthContext(auth?.user?.[0]?.email)
    setLoading(false)
    toast.success("Intro Updated!!")
    

  } catch (error) {
    console.error('Error in updatating Intro', error);
    setLoading(false)
  }
 

  handleClosePersonal(); 
 }


 const handledeleteExperience = async(index) =>{
  try {
    let newexper = [...JSON.parse(auth?.user?.[0]?.experience)];
    newexper.splice(index, 1); // Remove the item at the specified index
   

    // Make the API call with the updated experience array
    await axios.post(
      `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
      {
        email: auth?.user?.[0]?.email,
        exp: JSON.stringify(newexper),
      },
      {
        headers: {
          Authorization: "Bearer madebyashutosh",
        },
      }
    );

    // Update the auth context with the new experience array
    updateAuthContext(auth?.user?.[0]?.email);
    toast.success("Experience deleted successfully!");
  } catch (error) {
    console.log(error);
    toast.error("Error deleting experience.");
  }
 }
 
 const handledeleteEducation = async(index) =>{
  try {
    let newexper = [...JSON.parse(auth?.user?.[0]?.education)];
    newexper.splice(index, 1); // Remove the item at the specified index

    // Make the API call with the updated experience array
    await axios.post(
      `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/updatedintro`,
      {
        email: auth?.user?.[0]?.email,
        education: JSON.stringify(newexper),
      },
      {
        headers: {
          Authorization: "Bearer madebyashutosh",
        },
      }
    );

    // Update the auth context with the new experience array
    updateAuthContext(auth?.user?.[0]?.email);
    toast.success("Education deleted successfully!");
  } catch (error) {
    console.log(error);
    toast.error("Error deleting experience.");
  }
 }


 
  return (
    <div className={styles.rightMain}>
        <div className={styles.mainss}>
          <div className={styles.imagesandcover}> 
                <div className={styles.coverimagescontainer}>
                  <img src={auth?.user?.[0]?.cover_image ? auth?.user?.[0]?.cover_image :"https://wallpapers.com/images/hd/gears-linkedin-cover-mvrjmcq6p2mfs1sq.jpg" } alt='das' className={`img-fluid ${styles.coverimages}` } /> 
                  <span className={styles.penicon}  onClick={handleShow}>  <Pen  className={styles.penonhover} size={15}  /> </span>
                </div>   
                <div className={styles.userimage}>  
                  <div className={styles.profileImageContainer} onClick={handleShowProfileImage}>
                      <img className={styles.imgg} src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images :imgSrc} alt='das' />
                    
                  </div>
                </div>
          </div>
          <div className={styles.personelcontainer}>
              <div className={styles.leftpartprofile} >
              <span className={styles.nameha}>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname}</span>
              <span className={styles.designation}>{auth?.user?.[0]?.designation} {auth?.user?.[0]?.current_company ? ` @ ${auth?.user?.[0]?.current_company}` : "" }</span>
                 <span className={styles.short}>{auth?.user?.[0]?.shortDescription} </span>
                 <span className={styles.location}>{auth?.user?.[0]?.city} {auth?.user?.[0]?.state ? ` | ${auth?.user?.[0]?.state}` :""} {auth?.user?.[0]?.contactno ? ` | ${auth?.user?.[0]?.contactno}` :""} </span>
              </div>
              <div className={styles.Rightpartprofile} >
                <span className={styles.college}> <GraduationCap size={15}/> {auth?.user?.[0]?.college}</span>
                <span className={styles.college}> <ScrollText size={15}/> {auth?.user?.[0]?.qualification} {auth?.user?.[0]?.stream ?  ` | ${auth?.user?.[0]?.stream}` :""} </span>
              </div> 
             <div className={styles.peniconPersonal}>
             <span onClick={handleShowPersonal}>  <Pen    size={15} color={'#01a768'}  /> </span>
             </div>
          </div> 
          {/* <div className={styles.follersandfollwingcontainer}>
            <div className={styles.follwers}>0 Follwers</div>
             <div className={styles.folling}>0 Follwing</div>

          </div> */}

          <div className={styles.sociallogo}>
             
             {auth?.user?.[0]?.linkedin && (
                <div>
                <a href={auth?.user?.[0]?.linkedin} target="_blank" rel="noreferrer" className={styles.linkedin}> <Linkedin size={17} color={'#0e76a8'} /> </a>
               </div>
             )}
             
             {auth?.user?.[0]?.git_link && (
                 <div>
                 <a href={auth?.user?.[0]?.git_link} target="_blank" rel="noreferrer" className={styles.linkedin}> <Github size={17} color={'#0e76a8'} /> </a>
                </div>
             )}

               {auth?.user?.[0]?.other_link && (
                 <div>
                 <a href={auth?.user?.[0]?.other_link} target="_blank" rel="noreferrer" className={styles.linkedin}> <Globe size={17} color={'#0e76a8'} /> </a>
                </div>
               )}

               {auth?.user?.[0]?.email && (
                <div>
                <a href={auth?.user?.[0]?.email} target="_blank" rel="noreferrer" className={styles.linkedin}> <Mails size={17} color={'#0e76a8'} /> </a>
               </div>
             )}

                {auth?.user?.[0]?.contactno && (
                <div>
                <a href={auth?.user?.[0]?.contactno} target="_blank" rel="noreferrer" className={styles.linkedin}> <Phone size={17} color={'#0e76a8'} /> </a>
               </div>
             )}

            

            

            

            

          </div>
          <div className={styles.buttonscontainer}>
                {/* <div className={styles.buttonss}> <PenIcon size={15}/> Follow </div>
                <div className={styles.buttonss}> <MessageCircleMore size={15}/> Chat </div> */}
                
                   <div className={styles.buildwithresumecontainer}>
                        <div className={styles.buttonss}> <NotebookPen size={15}/> Build AI Resume  </div>
                        <span className={styles.newbutton}>New</span>
                   </div>
              </div>
          </div>
          <div className={styles.expericen}>
             
             <div className={styles.experheading}>
                <span className={styles.wrapper}>
                About Me 
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
               
                <Pen  size={15} color={'#01a768'}  onClick={handleShowaboutme} /> </div>  

             <p className={styles.aboutme}>{auth?.user?.[0]?.aboutme}</p>
          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}>
               
             <span className={styles.wrapper}>
                  Experience 
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
               
               <Pen  size={15} color={'#01a768'} onClick={handleShowExperience} /> </div> 
             <div className={styles.imgexp}>
                  {!auth?.user?.[0]?.experience || auth?.user?.[0]?.experience==='[]'  && (
                      <img src='https://cdn.dribbble.com/users/749470/screenshots/6165180/patient_experience.gif' alt='das' className={`${styles.expericenimg} img-fluid`} />
                  )} 
                  
              </div>

               <div className={styles.experiencmaincontianer}>
                    
                    <div className={styles.informationcontainer}>
                        {/* {JSON.parse(auth?.user?.[0]?.experience?.replace(/[\u0000-\u001F\u007F-\u009F]/g, ''))?.map((ele,index)=>{
                              return(
                              <div className={styles.eachsectionofexp}> 

                                 <div>
                                 <div className={styles.imagecontainer}>
                                        <img src="https://icons.veryicon.com/png/o/miscellaneous/zr_icon/company-23.png"  style={{height:'3rem' ,width:'3rem'}}/>
                                    </div>
                                  </div>
                                 <div>
                                 
                                <div className={styles.role}> {ele.role} </div>
                                <div className={styles.company}> {ele.company} </div>
                                <div> {ele.timePeriod} </div>
                                <div> {ele.companyType} .  {ele.companyLocation} </div>
                                <div> {ele.description} </div>
                                <div> 
                                  <ul>
                                  {ele?.points?.map((ele,index)=>{
                                  return(
                                    <>
                                    <li> {ele} </li>
                                    </>
                                    )
                                })} 
                                </ul>
                                </div>
                                </div> 
                                <div onClick={()=>handledeleteExperience(index)}>
                                  <Trash2 size={15} color={"red"} />
                                 </div>
                              </div>

                              
                              )
                            })} */}
                    </div>
               </div>

          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}>
             <span className={styles.wrapper}>
                  Education 
                
               </span> 
                <Pen  size={15} color={'#01a768'} onClick={handleShowEducation}/> </div> 
             
             <div className={styles.imgexp}>
                  {!auth?.user?.[0]?.education || auth?.user?.[0]?.education==='[]'  && (
                      <img src='https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs3/217649842/original/6a281a45a096906284efb8e24d07d060215c4747/create-animated-gif-or-lottie-json-svg-animation-for-web-and-mobile-app.png' alt='das' className={`${styles.expericenimg} img-fluid`} />
                  )} 
                  
              </div>

              <div className={styles.experiencmaincontianer}>
                    
                    {/* <div className={styles.informationcontainer}>
                        { JSON.parse( auth?.user?.[0]?.education.replace(/[\u0000-\u001F\u007F-\u009F]/g, ''))?.map((ele,index)=>{


                              return(
                              <div className={styles.eachsectionofexp}> 

                                 <div  >
                                 <div className={styles.imagecontainer}>
                                        <img src="https://i.pinimg.com/564x/ec/ff/cc/ecffccbdfb3381f5edf994d45913f737.jpg"  style={{height:'3rem' ,width:'3rem'}}/>
                                    </div>
                                  </div>
                                 <div>
                                 
                                <div className={styles.role}> {ele.college} </div>
                                <div className={styles.company}> {ele.college}  {ele.branch} </div>
                                <div className={styles.company}> {ele.companystartMonth}  {ele.companystartYear} - {ele.companyendMonth} {ele.companyendYear} </div>
                                <div> {ele.edcuationDesc} </div>
                           
                                <div> 
                                   
                                </div>
                                </div> 
                                <div className={styles.trashcontainer} onClick={()=>handledeleteEducation(index)}>
                                  <Trash2 size={15} color={"red"} />
                                 </div>
                              </div>

                              
                              )
                            })}
                    </div> */}
               </div>
          
          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}>  
               
             <span className={styles.wrapper}>
                  
                  <span className={styles.wrapper}>
                  Projects 
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
               
               </span> 

               <Pen  size={15} color={'#01a768'}/> </div> 
             <div className={styles.imgexp}> <img src='https://cdn.dribbble.com/users/2949461/screenshots/18074609/media/41b972cf2b56ec75936f59685f4b4958.gif' alt='das' className={`${styles.expericenimg} img-fluid`} /> </div>
          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}> 
             <span className={styles.wrapper}>
                  Certification 
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
                <Pen  size={15} color={'#01a768'}/> </div> 
             <div className={styles.imgexp}> <img src='https://media.licdn.com/dms/image/C4E12AQEABxT-WKn5RA/article-cover_image-shrink_600_2000/0/1595285682066?e=2147483647&v=beta&t=Hl6zy9cSGmKXAhEGl782R8ZFmtTjk9cqjuEXKxW4vDc' alt='das' className={`${styles.expericenimg} img-fluid`} /> </div>
          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}>
             <span className={styles.wrapper}>
                  Achievement 
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
                <Pen  size={15} color={'#01a768'}/> </div> 
             <div className={styles.imgexp}> <img src='https://cdn.domestika.org/c_fill,dpr_auto,f_auto,q_auto,w_820/v1587483114/content-items/004/280/247/coronavirusextra-original.gif?1587483114'  alt='das' className={`${styles.expericenimg}  img-fluid`} /> </div>
          </div>
          <div className={styles.expericen}>
             <div className={styles.experheading}>
               
               <span className={styles.wrapper}>
               Extra Curicular Activities  
                <span className={styles.aipowered}>AI Powered</span>
               </span> 
               <Pen  size={15} color={'#01a768'}/> </div> 
             <div className={styles.imgexp}> <img src='https://cdn.goodmanlantern.com/wp-content/uploads/2022/04/Group-6631.svg' alt='das' className={styles.expericenimg} /> </div>
          </div>
    
    
          <Modal show={show} onHide={handleClose}  size="lg"
         >
            <Modal.Body>
                  <img
                     src={selectedImage || (auth?.user?.[0]?.cover_image ? auth.user[0].cover_image : "https://wallpapers.com/images/hd/gears-linkedin-cover-mvrjmcq6p2mfs1sq.jpg")}
                     alt='cover'
                     className={`img-fluid ${styles.coverimages}`}
                  />
                  <div className={styles.uploadCoverContainer} > 
                        {/* <div>
                           <span className={styles.deleteButton}>  <Trash size={18}/>  Delete </span>
                        </div> */}
                         
                       
                        <div className={styles['input-container']}>
                                 <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleImageChange} 
                                    id="file-input" 
                                    className={styles['file-input']} 
                                 />
                                 <label htmlFor="file-input" className={`btn btn-primary ${styles['file-label']}`}>
                                    Change 
                                 </label>

                                 
                                    <span className={styles.savebutton} onClick={handleSaveImage}> {loading ? <HashLoader color="white" size={20} /> :"Apply"} </span>
                                 
                              </div>

                             
                             
                        
                       
                  </div>
                  
            </Modal.Body>
          </Modal>
          <Modal show={showProfileImage} onHide={handleCloseProfileImage}  size="sm"
         >
            <Modal.Body> 
               <div className={styles.profileMianContit}>
                     <img
                           src={selectedProfileImage || (auth?.user?.[0]?.images ? auth.user[0].images : imgSrc)}
                           alt='cover'
                           className={`img-fluid ${styles.profilemodel}`}
                        />
               </div>
                 
                  <div className={styles.uploadCoverContainer} > 
                         
                         
                       
                        <div className={styles['input-container']}>
                                 <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleProfileImageChange} 
                                    id="file-input" 
                                    className={styles['file-input']} 
                                 />
                                 <label htmlFor="file-input" className={`btn btn-primary ${styles['file-label']}`}>
                                    Change 
                                 </label>
                                    <span className={styles.savebutton} onClick={handleProfileSaveImage}>{loading ? <HashLoader color="white" size={20} /> :"Apply"}</span>
                              </div>
                  </div>
                  
            </Modal.Body>
          </Modal>

          <Modal show={showPersonal} onHide={handleClosePersonal}  size="lg">
            <Modal.Header closeButton className={styles.introheading}>Edit Intro</Modal.Header>
            <Modal.Body>  
              <div className={styles.introMainConti}>
              <div className={styles.imagediv2} onClick={handleShowProfileImage}>
                      <img
                           src={selectedProfileImage || (auth?.user?.[0]?.images ? auth.user[0].images : imgSrc)}
                           alt='cover'
                           className={`img-fluid ${styles.profilemodel}`}
                        />
                        <div>
                            <span className={styles.savebutton}  >{loading ? <HashLoader color="white" size={20} /> :"Change"}</span>
                            {/* <span className={styles.savebutton}  >{loading ? <HashLoader color="white" size={20} /> :"Delete"}</span> */}
                        </div>

                      </div>
                    <div className={styles.profileMianConti}> 

                       <div className={styles.firstlastcont}> 
                              <div className={styles.innderconfist}>
                                  <label htmlFor="name"> First Name:</label>
                                  <input type="text" id="name" name="name" value={name} onChange={(e)=>setName(e.target.value)} />
                              </div>
                              <div className={styles.innderconfist}> 
                                  <label htmlFor="name">Last Name:</label>
                                  <input type="text" id="name" name="name" value={lname} onChange={(e)=>setlName(e.target.value)} />
                              </div>
                       </div>
                       
                        <label htmlFor="heading">Heading</label>
                        <textarea
                                id="heading"
                                name="heading"
                                className={styles.textaree}
                                value={desc}
                                onChange={(e)=>setdesc(e.target.value)}
                                placeholder="Enter Intro...."
                                rows={4} // Adjust rows as needed
                                cols={50} // Adjust columns as needed
                        />

                       
                        <label htmlFor="contactId">Contact No:</label>
                        <input type="text" id="contactId" name="contactId"  value={contact} onChange={(e)=>setcontact(e.target.value)}/>

                        <label htmlFor="contactId">College Name</label>
                        <input type="text" id="contactId" name="contactId"  value={college} onChange={(e)=>setcollege(e.target.value)}/>
                       
                        <div className={styles.firstlastcont}> 
                              <div className={styles.innderconfist}>
                              <label htmlFor="contactId">Degree</label>
                                <input type="text" id="contactId" name="contactId"  value={degree} onChange={(e)=>setdegree(e.target.value)}/>

                              </div>
                              <div className={styles.innderconfist}> 
                              <label htmlFor="contactId">Stream</label>
                              <input type="text" id="contactId" name="contactId"  value={stream} onChange={(e)=>setstream(e.target.value)}/>
                              </div>
                       </div>
                        
                       

                        <label htmlFor="contactId">Recent Company if employed else write "Open to Work" </label>
                        <input type="text" id="contactId" name="contactId"  value={currentCompany} onChange={(e)=>setcurrentCompany(e.target.value)}/>

                        <label htmlFor="contactId"> Designation </label>
                        <input type="text" id="contactId" name="contactId"  value={designation} onChange={(e)=>setdesignation(e.target.value)}/>


                      
                        <div className={styles.firstlastcont}> 
                              <div className={styles.innderconfist}>
                              <label htmlFor="location">City:</label>
                                <input type="location" id="location" name="location" value={location} onChange={(e)=>setlocation(e.target.value)} />
                        
                              </div>
                              <div className={styles.innderconfist}> 
                              <label htmlFor="location">State:</label>
                              <input type="location" id="location" name="location" value={state} onChange={(e)=>setState(e.target.value)} />
                        
                              </div>
                       </div>
                        <label htmlFor="location">GitHub Link:</label>
                        <input type="location" id="location" name="location" value={git} onChange={(e)=>setgit(e.target.value)}  />

                        <label htmlFor="location">linkedin Link:</label>
                        <input type="location" id="location" name="location"  value={linkedin} onChange={(e)=>setlinkedin(e.target.value)}/>
                        
                        <label htmlFor="website"> Any Website Link</label>
                        <input type="website" id="website" name="website" value={website} onChange={(e)=>setWebsite(e.target.value)} />
                      </div>
                     
            </div>
              <div className={styles['save-container']}>    
                  <span className={styles.savebutton} onClick={handleSaveIntro}>{loading ? <HashLoader color="white" size={20} /> :"Save"}</span>
              </div>
            </Modal.Body>
          </Modal>

           <AIAboutMe handleCloseAboutMe={handleCloseAboutMe}  setLoading={setLoading}  showAbout={showAbout} HashLoader={HashLoader} loading={loading} updateAuthContext={updateAuthContext}/>
           <AIExperience handleCloseExperience={handleCloseExperience} handledeleteExperience={handledeleteExperience}  setLoading={setLoading}  showExperience={showExperience} HashLoader={HashLoader} loading={loading} updateAuthContext={updateAuthContext}/>
           <AIEducation handleCloseEducation={handleCloseEducation} handledeleteEducation={handledeleteEducation}  setLoading={setLoading}  showEducation={showEducation} HashLoader={HashLoader} loading={loading} updateAuthContext={updateAuthContext}/>
           

         
    </div>
  )
}

export default Right