import React from 'react'
import styles from "../../assets/Job.module.css"
import { useAuth } from '../../ResumeBuilder/builder/components/context/auth';
const Heading = () => {
    const { auth } = useAuth();
    
  return (
    <div className={styles.mainsa}>
             
              <h1 className={styles.sfass}>
                Hi, {auth?.user[0]?.firstname} {auth?.user[0]?.lastname}
                <img
                  src="https://em-content.zobj.net/source/noto-emoji-animations/344/waving-hand_1f44b.gif"
                  style={{ width: "2rem" }} 
                  alt="i"
                />
              </h1>
              <span className={ `${styles.sfs} text-center`}>Lets Help You Find The Dream Job</span>
            
     </div>
  )
}

export default Heading