import React from 'react'
import styles from "../../assets/Job.module.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import { useResume } from '../../ResumeBuilder/builder/components/context/Resume';
import { ArrowUpRight } from 'lucide-react';
const UseOurAI = () => {
    const Navigate = useNavigate();
    const { templates, handleTemplateChange } = useResume();
    const handleChange = (templateKey) =>{
      handleTemplateChange(templateKey)
  
      Navigate( '/welcome');
    }
  return (
    <div
            className={`card mt-4 ${styles.maif}` }
             
          >
            <div className="container"  >
              <div className="row "> 
              <div className='col-11 col-md-11'>
                <h1 className={styles.chalhat}>Your resume is ready! Select a template and download it for free</h1>
                <p className={styles.downchalhat}> <span className={styles.greendot}></span> Don't miss out—33.7L+ students have already grabbed their resume. Get yours now!</p>
              <Swiper
                    
                    className="mySwiper"
                    slidesPerView={1} // default to 1 for small screens
                    breakpoints={{
                      640: {
                        slidesPerView: 1, // 1 slide at a time on mobile devices
                      },
                      768: {
                        slidesPerView: 2.5, // 2 slides at a time on tablets
                      },
                      1024: {
                        slidesPerView: 2.5, // 4 slides at a time on larger screens
                      },
                    }}
                  >

                  {Object.values(templates).map((template, index) => {
                  return ( <>
                  
                  <SwiperSlide className={styles.swipper} onClick={() => handleChange(template.key)}>
                    <img  src={template.preview} alt={template.name} className={`img-fluid ${styles.dashoutourImg}`} />
                    <div className={styles.aaai}>
                        <span className={styles.buttonshow}>Use Resume <ArrowUpRight size={15}/> </span>  
                      </div>
                  </SwiperSlide>

                  </> ) }) }
                    
                    
                  </Swiper>
              </div>
                
              </div>
            </div>
          </div>
  )
}

export default UseOurAI