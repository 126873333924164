import React from 'react'
import MyResponsiveNavbar from './Navbar'
import Footer from './Footer'
import BottomBar from '../components/BottomBar'
import HelmetConfig from '../components/HelmetConfig'

const Application = () => {
 

   //   const [tex,settext] = useState(''); 
   //   const [arr, setarr] = useState([]); 
//      const [selectedSate , setSlectedSate] = useState(''); 
//     const arrstate = ["Bihar","UP","MP","Chandigarh"] 
//     const arrdist = {
//         "Bihar":["Muzaffarpur","samastipur","chapra"],
//         "UP":["lucknow","kanpur","ghaziabad"],
//         "MP":["bhopal","indore","gwalior"],
//         "Chandigarh":["chandigarh","pataudi","gurgaon"]
//     }

//   const handleSubmit = () =>{
//    setarr ([...arr, tex])
//   }
  
 

//   const handledelete = () =>{ 
 
//     const reducearray = arr.filter((item)=> item !== tex); 
//     console.log("reduce" , reducearray); 
//     setarr(reducearray)
//   }


  const numbers = [34, 7, 23, 32, 5, 62];

numbers.sort((a, b) => b - a);


//   useEffect(()=>{
//    console.log(arr) 
//    console.log(tex)
//   },[arr]) 


  return (
    <div>
       <HelmetConfig
          title="MyFuse.in-Application"
          description="Create and enhance your professional resume with AI assistance on MyFuse.in. Our platform offers personalized resume building, job matching, and career guidance to help you land your dream job. Explore opportunities across various industries and locations, and take the next step in your career journey with MyFuse.in."
          keywords="AI resume builder, job opportunities, career development, resume creation, job matching, professional resume, employment search, industry-specific jobs, location-based job search, career guidance, MyFuse.in jobs, resume enhancement, job portal, job search platform, AI Resume"
          canonical="https://myfuse.in/resume"
        />
     <div className='contianer'  > 
       <MyResponsiveNavbar/>
       <div className='row d-flex justify-content-center align-items-center'  style={{width:'100vw' ,  height:'90vh', backgroundColor:'#fafbfd'}}>
          <div className='col-10 d-flex justify-content-center'   >
              <span>
              <img  src='https://app.enhancv.com/images/noCoverLetter-c3a26a31f7bb59d4e45b.png' className='img-fluid' style={{height:'15rem'}}  alt='dd'/>
              <h2 className='text-center'>Pair your resume with a cover letter</h2>
                {/* <input type='text' value={tex} onChange={(e)=>settext(e.target.value)} />  
                <button className='btn btn-primary' onClick={(value)=>handleSubmit(value)}>Submit</button> 
                <button className='btn btn-danger' onClick={(value)=>handledelete(value)}>delete</button>  

                 <select onChange={(e)=>setSlectedSate(e.target.value)}> 
                     {arrstate.map((ele,key)=>{
                        return <option key={key} value={ele}>{ele}</option>
                     })}      
                 </select>  */}

                 {/* {selectedSate!=='' && (
                  <div>
                     <h3>Selected State is {selectedSate}</h3>
                        {
                            Object.keys(arrdist).map((ele,index)=>{
                               if(ele === selectedSate) {
                                 return <div key={index}>
                                    <h3>{arrdist[ele].map((elem,index)=>{
                                       return <p key={index}>{elem}</p>
                                       
                                    })}</h3>
                                    </div>
                               }
                            })
                        }
                  </div>
                 ) }
                   */}

              </span>
          </div>
         
       </div>
      </div> 
     <Footer/>
     <BottomBar/>
     </div>
  )
}

export default Application