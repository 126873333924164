import React, { useEffect, useState } from "react";
import styles from "../../assets/Analysis.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ChevronDown, ChevronUp, CircleHelp } from "lucide-react";
import { useResume } from "./components/context/Resume";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useJobs } from "./components/context/Jobs";
import OpenAI from "openai";
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const Analysis = () => {
  const { values } = useResume();
  const [isActive, setActive] = useState();
  const [response, setResponse] = useState();
  let [count, setCount] = useState(0);
  let [resumestructure, setresumeStructure] = useState(5);
  const { sections } = values;
  const {payment} = useJobs(); 
 
  
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className={styles.custom}>
      Get a detailed resume score and actionable tips to complete and strengthen your profile for better opportunities.
    </Tooltip>
  );
  const calculateScore = () => {
    let score = 0;
    const maxScore = 100;
    const pointsPerSection = 10;
    let store = 0;
    const excludedSections = [
      "Settings",
      "Sports Programming",
      "Profile",
      "Positions of Responsibility",
      "Awards",
    ];

    Object.keys(sections).forEach((key) => {
      const section = sections[key];
      if (
        !excludedSections.includes(section.name) &&
        section.data &&
        (Array.isArray(section.data)
          ? section.data.length > 0
          : Object.keys(section.data).length > 0)
      ) {
        score += pointsPerSection;
        store++;
        setresumeStructure(store);
      }
    });

    return Math.min(score, maxScore);
  };

  const getResoponse = async () => {
    try {
      const jsonString = JSON.stringify(values);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: "You are resume anlysist  ",
          },

          { role: "user", content: `Resume Content= ${jsonString}` },
          {
            role: "user",
            content:
              "Give me detailed suggestions of resume  in array of object with sectiontitle",
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      let data = completion.choices[0].message.content;

      const json_data = JSON.parse(data);
      setResponse(json_data);
      console.log(json_data); // Print the content in a readable format

      // setAts(json_data.ats);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResoponse();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let score = calculateScore();
    //  console.log(sc);
    setCount(score);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleclose = (index) => {
    if (isActive === index) {
      setActive();
    } else {
      setActive(index);
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        Analysis
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className={styles.do}>
            <CircleHelp size={18} />
          </span>
        </OverlayTrigger>
        <div className={styles.dor}></div>
      </div>
      {/* this is for the  circle  */}
      <div className={styles.circlesection}>
        <div className={styles.llod}>
          <div style={{ width: 130, height: 130, position: "relative" }}>
            <CircularProgressbar
              value={count}
              styles={buildStyles({
                pathColor: `#31a4f0`,
                textColor: "#333",
                trailColor: "#eee",
                strokeWidth: 8,
                textSize: "16px",
                textAlign: "center",
              })}
            />
            <div className={styles.customCircuit}>
              <div className={styles.percentagetext}>{`${count}%`}</div>
              <div className={styles.scorete}>Overall Score</div>
            </div>
          </div>
          <div className={styles.pregresBar}>
            <div className={styles.containerp}>
              <div className={styles.dloooo}>Resume Structure</div>
              <div className={styles.barp}>
                <div
                  className={styles.fillp}
                  style={{
                    width: `${resumestructure * 10}%`,
                    backgroundColor: "brown",
                  }}
                />
              </div>
            </div>

            <div className={styles.containerp}>
              <div className={styles.dloooo}>Measurable Results</div>
              <div className={styles.barp}>
                <div
                  className={styles.fillp}
                  style={{ width: `18%`, backgroundColor: "blue" }}
                />
              </div>
            </div>

            <div className={styles.containerp}>
              <div className={styles.dloooo}>Keyword Usage</div>
              <div className={styles.barp}>
                <div
                  className={styles.fillp}
                  style={{ width: `38%`, backgroundColor: "purple" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dor}></div>
      </div>

      <div className={styles.issis}>
        9 Issues <div className={styles.dor}></div>
      </div>
      <div className={styles.maint}>

      <div className={`${payment?.paymentStatus !== "success" ? styles.blur : ''}`}>

       
        <div className={styles.suffestions} style={{ paddingTop: "1rem" }}>
          {/* <div className={styles.dddk}>Resume Structure issues</div>  */}
          <div className={styles.llo}>
            {Object.keys(sections).map((key, index) => {
              const section = sections[key];
              // setCount(count++)
              if (
                [
                  "Settings",
                  "Sports Programming",
                  "Profile",
                  "Positions of Responsibility",
                  "Awards",
                ].includes(section.name)
              ) {
                return null;
              }
              return (
                <>
                  {section.data && section.data.length > 0 ? (
                    ""
                  ) : (
                    <div
                      className={`${styles.flfo} ${
                        isActive === index && styles.flfoac
                      }`}
                      key={index}
                      onClick={() => handleclose(index)}
                    >
                      <div className={styles.headree}>
                        <div className={styles.firstno}>
                          <div className={styles.ddepal}>
                            <div className={styles.blinkdot}></div>{" "}
                            {section.name} is missing
                          </div>
                          <div className={styles.dooso}>
                            in Resume Structure
                          </div>
                        </div>
                        <div>
                          {isActive === index ? (
                            <ChevronUp size={17} />
                          ) : (
                            <ChevronDown size={17} />
                          )}
                        </div>
                      </div>

                      {section.name === "Education" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Education" section of your resume, list
                              your highest degree first, including the title,
                              institution, location, and graduation date.
                            </li>
                            <li>
                              Highlight any honors or awards, and mention
                              relevant coursework or projects, especially those
                              related to the job you're applying for.
                            </li>
                            <li>
                              If your GPA is strong, include it. Keep the
                              information concise and use reverse chronological
                              order.
                            </li>
                            <li>
                              Tailor this section to emphasize aspects most
                              relevant to the job, presenting your educational
                              background clearly and effectively
                            </li>
                          </ul>
                        </div>
                      )}

                      {section.name === "Experience" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Experience" section, list jobs in reverse
                              chronological order, including your job title,
                              company name, location, and dates.
                            </li>
                            <li>
                              Briefly describe your key responsibilities and
                              achievements, focusing on quantifiable results and
                              relevant skills.
                            </li>
                            <li>
                              Use bullet points and start with strong action
                              verbs.
                            </li>
                            <li>
                              Tailor this section to highlight experiences most
                              relevant to the job you're applying for, ensuring
                              a clear and concise presentation.
                            </li>
                          </ul>
                        </div>
                      )}

                      {section.name === "Projects" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Projects" section, list projects in
                              reverse chronological order.
                            </li>
                            <li>
                              Include the project title, your role, and a brief
                              description.
                            </li>
                            <li>
                              Highlight key achievements and technologies used,
                              focusing on those relevant to the job you're
                              applying for.
                            </li>
                            <li>
                              Use bullet points for clarity and emphasize
                              quantifiable results
                            </li>
                          </ul>
                        </div>
                      )}

                      {section.name === "Technical Skills" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Technical Skills" section, list relevant
                              skills and technologies in a clear, organized
                              manner.
                            </li>
                            <li>
                              Use categories if needed (e.g., Programming
                              Languages, Tools, Frameworks).
                            </li>
                            <li>
                              Include proficiency levels if applicable (e.g.,
                              Beginner, Intermediate, Advanced).
                            </li>
                            <li>
                              Focus on skills directly related to the job you’re
                              applying for to demonstrate your technical
                              capabilities effectively.
                            </li>
                          </ul>
                        </div>
                      )}

                      {section.name === "Extra Curriculars" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Extracurriculars" section, briefly list
                              activities, organizations, or roles outside of
                              academics or work.
                            </li>
                            <li>
                              Include the name of the activity, your role or
                              involvement, and any notable achievements or
                              contributions.
                            </li>
                            <li>
                              Focus on those that demonstrate skills,
                              leadership, or qualities relevant to the job
                              you're applying for.
                            </li>
                            <li>
                              Use bullet points for clarity and keep
                              descriptions concise.
                            </li>
                          </ul>
                        </div>
                      )}
                      {section.name === "Achievements" && (
                        <div
                          className={`${styles.full}   ${
                            isActive === index && styles.sho
                          } `}
                        >
                          <ul>
                            <li>
                              In the "Achievements" section, list notable
                              accomplishments that showcase your skills and
                              capabilities.
                            </li>
                            <li>
                              Include awards, recognitions, certifications, or
                              significant milestones related to your field.
                            </li>
                            <li>
                              Provide a brief description of each achievement,
                              highlighting its relevance and impact.
                            </li>
                            <li>
                              Use bullet points for clarity and ensure that each
                              entry demonstrates how it contributes to your
                              qualifications for the job
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.suffestions}>
          <div className={styles.llo}>
            {response?.map((section, sectionIndex) => (
              <div
                className={`${styles.flfo} ${isActive === 11 && styles.flfoake}`}
                key={11}
                onClick={() => handleclose(11)}
              >
                <div className={styles.headree}>
                  <div className={styles.firstno}>
                    <div className={styles.ddepal}>
                      <div className={styles.blinkdotp}></div>{" "}
                      {section.sectionTitle}
                    </div>
                    <div className={styles.dooso}>in AI Response</div>
                  </div>
                  <div>
                    {isActive === 11 ? (
                      <ChevronUp size={17} />
                    ) : (
                      <ChevronDown size={17} />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.full}   ${
                    isActive === 11 && styles.sho
                  } `}
                >
                  <ul>
                    {section.suggestions.map((suggestion, suggestionIndex) => (
                      <li key={suggestionIndex} className={styles.blur}>
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}

            {/* {response.profile} */}

            {/* <div className={`${styles.flfo} ${ isActive==14 && styles.flfoac}`} key={14} onClick={()=>handleclose(14)}>
                        <div className={styles.headree} >  
                            <div className={styles.firstno}>
                                  <div className={styles.ddepal}>
                                    <div className={styles.blinkdotb}></div>   jobs    is missing
                                  </div>
                                  <div className={styles.dooso}>
                                  in Measurable Results
                                  </div>
                              </div> 
                              <div>
                                 {isActive===14 ?  (
                                   <ChevronUp size={17}/>
                                 ) : (
                                  <ChevronDown size={17}/>
                                 )}
                              </div>
                        </div> 
                        <div className={`${styles.full}   ${ isActive==14 && styles.sho} `}   >
                        Please Insure that at least one active company and role to your work experience
                        Please Insure that at least one active company and role to your work experience
                        Please Insure that at least one active company and role to your work experience
                        Please Insure that at least one active company and role to your work experience
                        </div> 
                       </div> */}
          </div>
        </div> 
        </div>
       {payment?.paymentStatus !== "success" && (
        <div className={styles.ddd}> 
         <span className={styles.ddodd}>
              <h5 className={styles.dd}>Upgrade to Premium  to get a more in-depth resume feedback.</h5>
              <p className={styles.paraa}>Improve your resume with a detailed resume analytics report and personalized tips on how to take it to the next level.</p>
            <div className={styles.upgradde}>
                 <span className={styles.eeo}>Upgrade</span>
            </div>
         </span>
        </div>
       )}
        
      </div>
    </div>
  );
};

export default Analysis;
