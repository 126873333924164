import React, { useEffect, useState } from "react";
import MyResponsiveNavbar from "../Navbar";
import Footer from "../Footer";
import styles from "../../assets/Admin/ResumeBuilder.module.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { PenBoxIcon, Plus, Trash, Trash2 } from "lucide-react";
import { toast } from "react-hot-toast";

const ResumeBuilder = () => {
  const [resumeName, setResumeName] = useState("");
  const [resumeType, setResumeType] = useState("");
  const [filedName, setfieldName] = useState("");
  const [subfiledName, setsubfieldName] = useState("");
  const [resumePhoto, setResumePhoto] = useState(null);
  const [sections, setSections] = useState([]);
  const [resumePreview, setResumePreview] = useState(null); 
  const [selected,setSeleted] = useState(null); 
  const [show, setShow] = useState(false);
  const [resumefields, setResumeFields] = useState("");
  const [selectedField, setSelectedFields] = useState("");  
  const [allresume ,setallresume] = useState([]); 
  const [field , setfieldSeleted] = useState(""); 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading , setLoading] = useState(false); 
  const [showsubfiled, setShowsubfiled] = useState(false);
  const handleCloseSubfiled = () => setShowsubfiled(false);
  const handleShowsubfiled = () => setShowsubfiled(true);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const datalocal = JSON.parse(localStorage.getItem("auth"));

    try {
      // Create a FormData object to handle file upload
      setLoading(true); 
      const formData = new FormData();
      formData.append("name", resumeName);
      formData.append("section_add", sections);
      formData.append("field", selected);
      formData.append("type", resumeType);
      formData.append("resume", resumePhoto); // Assuming resumePhoto is the file object
      formData.append("fieldtype", field); // Assuming resumePhoto is the file object

       await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/uploadresume`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
            "Content-Type": "multipart/form-data", // Important for sending files
          },
        }
        
      );
      setLoading(false)
      toast.success("Created")
      showresume();
      setResumeName('');  
      setResumePhoto(''); 
      setResumePreview(null); 
  

    } catch (error) {
      console.error(error);
      alert("There was an error uploading the resume. Please try again.");
      setLoading(false)
    }
  };

  const handlePhotoChange = (e) => {
    setResumePhoto(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setResumePreview(previewUrl);
    } else {
      setResumePreview(null);
    }
  };

  const handleSectionsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSections([...sections, value]);
    } else {
      setSections(sections.filter((section) => section !== value));
    }
  };

  const handleCreateField = async () => {
    try {
      const datalocal = JSON.parse(localStorage.getItem("auth"));
       await axios.post(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/createResumeField`,
        {
          fieldName: filedName,
        },
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      allField();
      setfieldName("");
    } catch (error) {
      console.log(error);
    }
  };

  const allField = async () => {
    try {
      const datalocal = JSON.parse(localStorage.getItem("auth"));
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/getresumefieldAndsubField`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      setResumeFields(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletefield = async (value) => {
    try {
      const datalocal = JSON.parse(localStorage.getItem("auth"));
       await axios.delete(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/DeleteField/${value}`,
        {
          headers: {
            Authorization: `Bearer ${datalocal?.token}`,
          },
        }
      );
      allField();
    } catch (error) {
      console.log(error);
    }
  };
 
  const handleSubCreateField = async() =>{
    
    try {
        const datalocal = JSON.parse(localStorage.getItem("auth")); 
        let combinedSubFild ='' ;
           // Optional: If you need to use the combined field names for some purpose.
                    Object.values(resumefields).map((value)=>{
                        if(value.id === selectedField){
                              
                              if(value.sub_field==null) {
                                combinedSubFild = subfiledName;
                              }
                              else {
                                combinedSubFild = value.sub_field + ',' + subfiledName

                              }
                               
                            }  
                            
                            
                        
                    })        
           

       await axios.post(
          `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/addSubFieldToFeild`, 
          {
            subfieldName:combinedSubFild,
            fieldId:selectedField
          },
          {
            headers: {
              Authorization: `Bearer ${datalocal?.token}`,
            },
          }
        );
        setsubfieldName('')
        allField();
      } catch (error) {
        console.log(error);
      }
    

  }

  const showresume = async () =>{
    try {
 
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/admin/getallresume`,
      );
      if (data?.success) {
        await setallresume(data?.result);
       
      } else {
        toast.error("Failed to get Blog");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!!!");
    } 
  }
  useEffect(() => {
    showresume();
    allField();
  }, []);


 

  return (
    <>
      <MyResponsiveNavbar />
      <div className={styles.main}>
        <div className={styles.halfleft}>
          <form onSubmit={handleFormSubmit} className={styles.form}>
            <div className={styles.formGroup}>
              <label htmlFor="resumeName">Resume Name:</label>
              <input
                type="text"
                id="resumeName"
                value={resumeName}
                onChange={(e) => setResumeName(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
            <label htmlFor="resumeName">Select Field Name:</label>
            <select id="resumeName" name="resumeName" onChange={(e) => setSeleted(e.target.value)}   >
            <option value="" disabled selected>
                Select an option
            </option>
              {Object.values(resumefields).map((value, key) => (
                <option key={key} value={value.id}>
                  {value.field}
                </option>
              ))}
            </select>
          </div>
            <div className={styles.formGroup}>
              <label htmlFor="resumeName">Resume Sub Field:</label>
              <select id="resumeName" name="resumeName" onChange={(e) => setfieldSeleted(e.target.value)}   >
              <option value="" disabled selected>
                Select an option
            </option>
              {Object.values(resumefields).map((value, key) => {
               if(value.id === selected) { 
                
                return value.sub_field?.split(',').map((subValue, subKey) => (
                    <option key={subKey} value={subValue.trim()}>
                      {subValue.trim()}
                    </option>
                  ));
            } 
           })}
            </select>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="resumeType"> Select Resume:</label>
              <select
                id="resumeType"
                value={resumeType}
                onChange={(e) => setResumeType(e.target.value)}
                required
              >
                <option value="">Select Type</option>
                <option value="iitism">Double Column</option>
                <option value="iitb">Elegant</option>
                <option value="iitl">Professional</option>
                <option value="iite">Latest</option>
                <option value="iitd">Polished</option>
                <option value="iita">Modern</option>
                <option value="iitn">Stylish</option>
                <option value="iitk">Timeline</option>
                <option value="iitc">Experienced</option>
                <option value="iitg">Multiple</option>
                <option value="mit">Finished</option>

              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="resumePhoto">Resume Photo:</label>
              <input
                type="file"
                id="resumePhoto"
                accept="image/*"
                onChange={handlePhotoChange}
              />
            </div>

            <div className={styles.formGroup}>
              <label>Sections to Add:</label>
              <div>
                <label>
                  <input
                    type="checkbox"
                    value="profile"
                    onChange={handleSectionsChange}
                  />
                  Profile
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="experience"
                    onChange={handleSectionsChange}
                  />
                  Experience
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="education"
                    onChange={handleSectionsChange}
                  />
                  Education
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="technicalSkills"
                    onChange={handleSectionsChange}
                  />
                  Skills
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="projects"
                    onChange={handleSectionsChange}
                  />
                  Projects
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="awards"
                    onChange={handleSectionsChange}
                  />
                  Awards
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="positionsOfResponsibility"
                    onChange={handleSectionsChange}
                  />
                 Positions of Responsibility
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="achievements"
                    onChange={handleSectionsChange}
                  />
                 Achievements
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="extraCurriculars"
                    onChange={handleSectionsChange}
                  />
                Extra Curriculars
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="sportsProgramming"
                    onChange={handleSectionsChange}
                  />
               Sports Programming
                </label>
              </div>
            </div>

            <div className={styles.buttos}>
              <button
                className={styles.submitButton}
                variant="primary"
                onClick={handleShow}
              >
                Create Fields
              </button>

              <button
                className={styles.submitButton}
                variant="primary"
                onClick={handleShowsubfiled}
              >
                Create Sub Fields
              </button>

              <button type="submit" className={styles.submitButton}>
                 {loading ? "loading...":" Submit"}
              </button>
            </div>
          </form>
        </div>
        <div className={styles.halfright}>
          <img src={resumePreview} className="img-fluid" alt="dasd" />
        </div>
      </div> 




      <div className={styles.blogHistoryContainer}>
        <h1 className={styles.blogHistroyHeading}> Posted Resume </h1>
        <table className={styles.blogTable}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Logo</th>
              <th>Name</th>
              <th>Short Description</th>
              {/* <th>Tag</th> */}
               <th>Key</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allresume.map((value, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td><img src={value.images} alt="dasdf" /></td>
                <td>{value.name}</td>
                <td>{value.field} - {value.sub_field} </td>
                {/* <td>{value.section_add}</td> */}
                <td>{value.type} </td>
                <td className={styles.do}>
                  <button >
                    {" "}
                    <PenBoxIcon size={15} />{" "}
                  </button>
                  <button className={styles.dele}  >
                    {" "}
                    <Trash2 size={15} />{" "}
                  </button>
                </td>
              </tr> 
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h3>Create Field</h3>

          <div className={styles.formGroup}>
            <label htmlFor="resumeName">Field Name:</label>
            <input
              type="text"
              id="resumeName"
              value={filedName}
              onChange={(e) => setfieldName(e.target.value)}
              required
            />
          </div>
          <div>
            {/* create submit button */}
            <button
              className={`mt-3 ${styles.submitButton}`}
              variant="primary"
              onClick={handleCreateField}
            >
               Add <Plus size={15}/>
            </button>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Field Name</th>
                  {/* <th>Sub Field</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(resumefields).map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.field}</td>
                    {/* <td>{value.sub_field}</td> */}
                    <td onClick={() => handleDeletefield(value.id)}>
                      {" "}
                      <Trash size={15} color={"red"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showsubfiled} onHide={handleCloseSubfiled}>
        <Modal.Body>
          <h3>Create Sub Field</h3>

          <div className={styles.formGroup}>
            <label htmlFor="resumeName">Select Field Name:</label>
            <select id="resumeName" name="resumeName"   onChange={(e) => setSelectedFields(e.target.value)} > 
            <option value="" disabled selected>
                Select an option
            </option>
              {Object.values(resumefields).map((value, key) => (
                <option key={key} value={value.id}>
                  {value.field}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="resumeName"> Sub Field Name:</label>
            <input
              type="text"
              id="resumeName"
              value={subfiledName}
              onChange={(e) => setsubfieldName(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              className={`mt-3 ${styles.submitButton}`}
              variant="primary"
              onClick={handleSubCreateField}
            >
              Add <Plus size={15}/>
            </button>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Field Name</th>
                  <th>Sub Field</th>
                  
                </tr>
              </thead>
              <tbody>
                {Object.values(resumefields).map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.field}</td>
                    <td>{value.sub_field?.split(",").map((sub, index) => (
                                <span className={styles.fosr} key={index}>{sub.trim()}</span>
                      ))}</td> 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal> 



    </>
  );
};

export default ResumeBuilder;
