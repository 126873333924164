import React from "react";
import styles from "../../assets/ResumeHomePage/Payment.module.css";
import { CircleCheck, CircleCheckBig, SlidersHorizontal } from "lucide-react";
import Hat from '../../components/Payment'

const Payment = () => {

   
  const datalocal = JSON.parse(localStorage.getItem("auth")); 
  return (
    <div className={styles.main}>
      <div className={styles.headingContianer}>
        <h1 className={styles.title}>Choose your plan.</h1>
        <p>Unlock more career possibilities with MyFuse Premium</p>
      </div>

      <div className={styles.paymentContainer}>
        <div className={styles.b}>
          <div className={styles.badgeContainer}>
            <div>
              <img
                src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/vrpymuwhohr1dxwxocbr"
                className="img-fluid"
                height={70}
                width={70}
              />
              <div className={styles.discounttext}>100% off</div>
            </div>
          </div>
          <div className={styles.dsr}>
            <div className={styles.linee}></div>
            <span className={styles.d}>Free</span>
          </div>
          <div className={styles.ddool}>
            <div className={styles.cutContianainer}>
              <span className={styles.cut}>₹299</span>

              <span className={styles.save}>Save 100%</span>
            </div>
            <div className={styles.cc}>
              <h1 className={styles.yxlSx}>Free</h1>
            </div>
            <div>
              <div>
                <h4 className={styles.frof}>Explore More, Spend Nothing!</h4>
              </div>
            </div>
          </div>
          <div className={styles.buttcontainer}>
            <div className={styles.buttinactive}>Get Started</div>
          </div>
          <div className={styles.money}>
            <CircleCheck size={15} />
            Refunds? Nah. Laughter? Guaranteed!
          </div>
          <div className={styles.hr}></div>
          <div className={styles.featureContainer}>
            <ul>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Explore Unlimited Resume Template
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Explore Jobs in different Domain
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.a}>
          <div className={styles.badgeContainer}>
            <div>
              <img
                src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/vrpymuwhohr1dxwxocbr"
                className="img-fluid"
                height={70}
                width={70}
              />
              <div className={styles.discounttext}>25% off</div>
            </div>
          </div>
          <div className={styles.dsr}>
            <div className={styles.linee}></div>
            <span className={styles.d} style={{ color: "#4840eb" }}>
              Most Popular
            </span>
          </div>
          <div className={styles.ddool}>
            <div className={styles.cutContianainer}>
              <span className={styles.cut}>₹330</span>

              <span className={styles.save}>Save 25%</span>
            </div>
            <div className={styles.cc}>
              <h1 className={styles.yxlSx}>
                <span className={styles.dreeee}>₹</span>82.33
              </h1>
              <h4 className={styles.papa}>/month</h4>
            </div>
            <div>
              <div>
                <h4 className={styles.frof}>Pay ₹247 once</h4>
              </div>
            </div>
          </div>
          <div className={styles.buttcontainer}>
             
            {
              datalocal?.token ==='' ? "" :(
                <Hat/>
              )
            }
          </div>
          <div className={styles.money}>
            <CircleCheck size={15} /> 3 days Money Back GUARANTEED
          </div>
          <div className={styles.hr}></div>
          <div className={styles.featureContainer}>
            <ul>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Basic Job Suggestions
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Unlimited AI-Assisted Resume Creation
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Standard Resume Scoring
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                ATS-Compatible Template
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Unlimited Resume Edits
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Unlimited Downloads
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Beginner's How-To Guide
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.b}>
          <div className={styles.badgeContainer}>
            <div>
              <img
                src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/vrpymuwhohr1dxwxocbr"
                className="img-fluid"
                height={70}
                width={70}
              />
              <div className={styles.discounttext}>25% off</div>
            </div>
          </div>
          <div className={styles.dsr}>
            <div className={styles.linee}></div>
            <span className={styles.d}>Premium</span>
          </div>
          <div className={styles.ddool}>
            <div className={styles.cutContianainer}>
              <span className={styles.cut}>₹1000</span>

              <span className={styles.save}>Save 25%</span>
            </div>
            <div className={styles.cc}>
              <h1 className={styles.yxlSx}>
                <span className={styles.dreeee}>₹</span>249.00
              </h1>
              <h4 className={styles.papa}>/month</h4>
            </div>
            <div>
              <div>
                <h4 className={styles.frof}>Pay ₹747 once</h4>
              </div>
            </div>
          </div>
          <div className={styles.buttcontainer}>
            <div className={styles.buttinactive}>Upgrade</div>
          </div>
          <div className={styles.money}>
            <CircleCheck size={15} /> 3 days Money Back GUARANTEED
          </div>
          <div className={styles.hr}></div>
          <div className={styles.featureContainer}>
            <ul>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Includes All Basic Features
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Advanced How-To Guide
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Professional Resume Samples
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                AI-Enhanced Resume Optimization
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Comprehensive Resume Analysis & Feedback
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Enhanced ATS Scoring & Insights
              </li>
              <li>
                <CircleCheckBig size={15} className={styles.icon} />
                Personalized Job Recommendations
              </li>{" "}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
