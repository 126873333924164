import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import styles from "../assets/Model.module.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Tooltip from 'react-bootstrap/Tooltip';
import Payment from "./Payment";
import { CheckCheckIcon, CircleCheck, CircleHelp } from "lucide-react";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import { useNavigate } from "react-router-dom";

function Model({ modalShow, setModalShow }) { 
   const [active , setActive] = useState(3); 
    const {auth} = useAuth();  
    const Navigate = useNavigate(); 
   const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className={styles.custom}>
        This section ensures your resume content matches the job details effectively.
     
        
    </Tooltip>
  );

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      centered  
 
    >
      
      <Modal.Body className={styles.bodys}>
        {/* <div className={styles.close}>
          <span
            className={`${styles.closeButton} close`}
            onClick={() => setModalShow(false)}
          >
            <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
          </span>
        </div> */}

        <div className="container" style={{position:'relative'}} >
           <div className={styles.close} style={{position:"absolute" , top:"0rem" , right:"0rem"}}>
          <span
            className={`${styles.closeButton} close`}
            onClick={() => setModalShow(false)}
          >
            <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
          </span>
        </div>
          <div className="row">
            <div className={`col-12 col-md-7 ${styles.price}`}>
               
          <div className={styles.navbarheading}>
            <span className={styles.ddlo}> MyFuse.in  
            <div className={styles.premium}>Premium</div>
               </span>
            
          </div>
          <div className={styles.ddool}> 
            <div className={styles.dldod}>
              <h1 className={styles.number}>  <span className={styles.ffo}>₹</span>{active === 1 ? "99" : active === 3 ? "82.33" : "69.8"}</h1> 
              <h4 className={styles.bewbumb}>/m</h4>  
              </div> 
              <div>
                  
                  
            
                  <div>
                       <RenderPaymentText active={active} />
                   
                  </div>
                   
              </div>
          </div>
          <div className={styles.payy}>
             <div>
              <h4 className={styles.billngperiond}>Select Billing Period</h4>
              <div className={styles.pay}>
                 
                  {/* <div className={`${styles.payyy}  ${active===1 ? styles.active : ""}  `} onClick={()=>setActive(1)}>
                       One Month
                  </div> */}
                  <div className={`${styles.payyy}  ${active===3 ? styles.active : ""}  `} onClick={()=>setActive(3)}>
                      Three Months <span className={styles.dddodod}>Popular</span>
                  </div>
                  {/* <div className={`${styles.payyy}  ${active===5 ? styles.active : ""}  `} onClick={()=>setActive(5)}>
                       Five Months
                  </div> */}
                   
                </div>
              </div>
          
          

           {auth.token==="" ? (
            <div className={styles.notlogin} onClick={()=>Navigate('/login')}> Login To Pay </div>
           ) :  <Payment active={active}/>   }

          </div>
            </div>
            <div className={`col-12 col-md-5 ${styles.second}` }>
               <div>
                    <h5 className={styles.hh}>All features, one  <span className={styles.pro}>Premium</span>.</h5> 
                    <span className={styles.ff}>3,643 Premiuns Brought in the last 24 hours</span>
                </div> 
               <div>
                <h5 className={styles.hh}>What you get</h5>
                <ul className={styles.ul}>
                <li className={styles.list}> 
                  
                 <span><CheckCheckIcon size={16} color={"green"} />  Professional Templates </span>  
                
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger> 
                </li>
                   <li className={styles.list}> 
                      <span><CheckCheckIcon size={16} color={"green"} /> AI-Powered Content Creation </span>  
                      <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger> 
                    
                      </li>
                   <li className={styles.list}> 
                    
                    <span> <CheckCheckIcon size={16} color={"green"} /> Resume Optimization</span> 
                    <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger>
                     </li>
                   <li className={styles.list}> <span><CheckCheckIcon size={16} color={"green"} /> Job Description Matching</span> 
                   <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger>
                   </li>
                   <li className={styles.list}> <span> <CheckCheckIcon size={16} color={"green"} /> Personalized Job Recommendations </span>
                   <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger>
                   </li>
                   <li className={styles.list}> <span> <CheckCheckIcon size={16} color={"green"} /> Career Progress Tracking </span>
                   <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger>
                   </li>
                   <li className={styles.list}>  <span><CheckCheckIcon size={16} color={"green"} /> Exclusive Career Resources </span>
                   <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <span className={styles.do}><CircleHelp size={15} /></span>
                 </OverlayTrigger>
                   </li>
                   
                </ul>
               </div> 
              
              <div className={styles.mondyback}>
                <CircleCheck size={15} style={{marginRight:'4px'}}/>   14 DAY MONEY-BACK <br></br> GUARANTEED 
              </div>
               
            </div>
          </div>
        </div>
        <div>
         
        </div>
      </Modal.Body>
    </Modal>
  );
}

const RenderPaymentText = ({ active }) => {
  if (active === 1) {
    return <h4 className={styles.dsfs}>Pay ₹99 once</h4>;
  } else if (active === 3) {
    return <h4 className={styles.dsfs}>Pay ₹247 once</h4>;
  } else {
    return <h4 className={styles.dsfs}>Pay ₹349 once</h4>;
  }
};

export default Model;
