import React, { useState } from "react";
import styles from "./ResumeBuilder.module.css";
import {
  ArrowDownToLine,
  BarChart,
  BriefcaseBusiness,
  ChevronDown,
  ChevronUp,
  ClipboardList,
  FileInput,
  FileText,
  ImageDown,
  PenBoxIcon,
  Printer,
} from "lucide-react";
import { useJobs } from "./components/context/Jobs";
import { useResume } from "./components/context/Resume";
import { saveAs } from "file-saver";
import { htmlToCanvasImage } from "../utils/html_to_canvas_image";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import htmlDocx from "html-docx-js/dist/html-docx";
import Model from "../../components/Model";
const RightTop = () => {
  const { activeResume, setActiveResume } = useJobs();
  const { countScore, payment } = useJobs();
  const [active, setActive] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { values, printContainerRef, selectedTemplate } = useResume();

  const handleDownladImage = async () => { 
    //this is perfect
    // const canvas = await html2canvas(printContainerRef.current);
    // const imgData = canvas.toDataURL("image/png");
  
    // const link = document.createElement("a");
    // link.href = imgData;
    // link.download = `${values.name}.png`;
    // link.click();

    //this is also perfect 
    // const canvas = await html2canvas(printContainerRef.current);
    // canvas.toBlob((blob) => {
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.download = `${values.name}.png`;
    //   link.click();
    //   URL.revokeObjectURL(url); // Clean up after download
    // });
    

    //this is also perfect 
    const canvas = await html2canvas(printContainerRef.current);
    const imgData = canvas.toDataURL("image/png");
    const response = await fetch(imgData);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${values.name}.png`;
    link.click();
    

  };


  const handleDownloadPDF = () => {
    // add page margins to the @page css and a4 size
    const pageMargins = selectedTemplate.page_margins;
    const style = document.createElement("style");
    style.innerHTML = `@page { margin: ${pageMargins} !important; size: A4 !important; }`;
    const printContent = document.getElementById("print_content");
    printContent.style.width = `calc(210mm - 2 * ${pageMargins})`;
    const originalPadding = printContainerRef.current.style.padding;
    printContainerRef.current.style.padding = "0";
    document.head.appendChild(style);
    window.print();
    // remove the style tag
    style.remove();
    // reset the print content padding
    printContent.style.width = "100%";
    printContainerRef.current.style.padding = originalPadding;
  };

  // this is perfect but size is too large 
  const handleGeneratePdf = () => {
    html2canvas(printContainerRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 0.6);
      const pdf = new jsPDF();
      const imgWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // Adjust the position to start the new image at the top of the next page
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      pdf.save("download.pdf");
    });
  };
  
  
   
  

  // const handleGenerateWord = () => {
  //   const content = printContainerRef.current.innerHTML; // Get the HTML content as a
  //   console.log(content);

  //   const blob = htmlDocx.asBlob(content);
  //   const url = URL.createObjectURL(blob); // Create a URL for the blob
  //   const link = document.createElement("a"); // Create a temporary anchor element
  //   link.href = url;
  //   link.download = "document.docx"; // Set the download attribute with the desired file name
  //   document.body.appendChild(link); // Append the anchor to the body
  //   link.click(); // Programmatically click the link to trigger the download
  //   document.body.removeChild(link); // Remove the anchor from the body
  //   URL.revokeObjectURL(url); // Revoke the object URL to free up memory
  // };

  return (
    <div className={styles.dldo}>
      <div className={styles.leftdo}>
        <div
          className={`${styles.ddi} ${
            activeResume === 1 ? styles.ddiactive : ""
          }`}
          onClick={() => {
            setActiveResume(1);
          }}
        >
          <FileText size={15} /> <span className={styles.eoe}> Preview </span>
        </div>

        <div
          className={`${styles.ddi} ${
            activeResume === 2 ? styles.ddiactive : ""
          }`}
          onClick={() => {
            setActiveResume(2);
          }}
        >
          <BarChart size={15} />
          <span className={styles.eoe}>
            Analysis
            <span className={styles.sore}> {countScore} </span>
          </span>
        </div>
        <div
          className={`${styles.ddi} ${
            activeResume === 3 ? styles.ddiactive : ""
          }`}
          onClick={() => {
            setActiveResume(3);
          }}
        >
          <ClipboardList size={15} />{" "}
          <span className={styles.eoe}> Matching </span>
        </div>
        <div
          className={`${styles.ddi} ${
            activeResume === 4 ? styles.ddiactive : ""
          }`}
          onClick={() => {
            setActiveResume(4);
          }}
        >
          <PenBoxIcon size={15} /> <span className={styles.eoe}> Designs </span>
        </div>
        <div
          className={`${styles.ddi} ${
            activeResume === 5 ? styles.ddiactive : ""
          }`}
          onClick={() => {
            setActiveResume(5);
          }}
        >
          <BriefcaseBusiness size={15} /> <span className={styles.eoe}> Jobs </span>
        </div>
      </div>
      <div className={styles.rightdo}>
        <div className={styles.allos}>
          <div className={styles.buttonsall} onClick={() => setActive(!active)}>
            <ArrowDownToLine size={15} />{" "}
            <span className={styles.eoe}>
              {" "}
              Download{" "}
              {active ? (
                <ChevronUp size={15} />
              ) : (
                <ChevronDown size={15} />
              )}{" "}
            </span>
          </div>

          {active && (
            <div className={styles.slso}>
              <div
                className={styles.dlee}
                onClick={
                  payment?.paymentStatus !== "success"
                    ? () => setModalShow(true)
                    : handleGeneratePdf
                }
              >
                <FileInput size={15} />{" "}
                <span className={styles.eoe}> Download PDF </span>
              </div>

              <div
                className={styles.dlee}
                onClick={
                  payment?.paymentStatus !== "success"
                    ? () => setModalShow(true)
                    : handleDownloadPDF
                }
              >
                <Printer size={15} />{" "}
                <span className={styles.eoe}> Print PDF </span>
              </div>
              <div
                className={styles.dlee}
                onClick={
                  payment?.paymentStatus !== "success"
                    ? () => setModalShow(true)
                    : handleDownladImage
                }
              >
                <ImageDown size={15} />{" "}
                <span className={styles.eoe}> Download PNG </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default RightTop;
