import React from "react";
import { Route, Routes } from "react-router-dom";
import ResumeBuilder from "./builder/ResumeBuilder";
const Resume = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ResumeBuilder />} />
      </Routes>
    </>
  );
};

export default Resume;
