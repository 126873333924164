import React from 'react';
import styles from "../assets/BottomBar.module.css";
import { HomeIcon, FilePen, BriefcaseBusinessIcon, ClipboardList,  Feather } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import { useJobs } from '../ResumeBuilder/builder/components/context/Jobs';
const BottomBar = () => {
  const navigate = useNavigate();
 const {selected , setSelected} = useJobs();
  const handleItemClick = (itemNumber, route) => {
    setSelected(itemNumber);
    navigate(route);
  };

  

  return (
    <div className={styles.main}>
      <div className={styles.containerall}>
        <div className={`${styles.iconCont} ${selected === '1' ? styles.selected : ''}`}   onClick={() => handleItemClick('1', '/welcome/dashboard')} >
          <div className={`${selected === '1' ? styles.bar : ''}`}></div>
          <HomeIcon  size={14} />  
          <span className={styles.title} >Home</span>
        </div>
        <div className={`${styles.iconCont} ${selected === '2' ? styles.selected : ''}`} onClick={() => handleItemClick('2', '/airesume')} style={{marginRight:'-20px'}}>
        <div className={`${selected === '2' ? styles.bar : ''}`}></div>
          
          <FilePen  size={14}/>
          <span className={styles.title}>Resume</span>
        </div>
        <div className={styles.jobsdev}>
          <div className={`${styles.iconCont} ${styles.jobsinnerr} ${selected === '3' ? styles.selectedd : ''}`} onClick={() => handleItemClick('3', '/jobs')}>
            <BriefcaseBusinessIcon />
            <span className={styles.title}>Jobs</span>
          </div>
          <div className={styles.round}></div>
        </div>
        <div className={`${styles.iconCont} ${selected === '4' ? styles.selected : ''}`} onClick={() => handleItemClick('4', '/welcome/jobapplication')} style={{marginLeft:'-20px'}}>
        <div className={`${selected === '4' ? styles.bar : ''}`}></div>
          <ClipboardList  size={14}/>
          <span className={styles.title}>Applied</span>
        </div>
        <div className={`${styles.iconCont} ${selected === '5' ? styles.selected : ''}`} onClick={() => handleItemClick('5', '/blog')}>
        <div className={`${selected === '5' ? styles.bar : ''}`}></div>
        
          <Feather size={14} />
          <span className={styles.title}>Blog</span>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
