import React, { useEffect, useState } from "react";
import styles from "../../assets/ResumeHomePage/Section3.module.css";
const Section3 = () => {
  const [activeImage, setActiveImage] = useState(1);

  const handleSetActiveImage = (value) => {
    setActiveImage(value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prevImage) => (prevImage < 4 ? prevImage + 1 : 1));
    }, 3000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className={styles.main}>
      {/* heading */}
      <div className={styles.headingContainer}>
        <span className={styles.f}>
        Quickly create a standout resume that lands your dream job
        </span>
      </div>
      {/* inner conatainer  */}
      <div className={styles.innerContainer}>
        {/* Right Section  */}
        <div className={styles.rightSection}>
          {activeImage === 1 ? (
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/kdk1h2npi1npuxfhwi7u"
              className={`img-fluid ${styles.imgfluid}`}
              alt="Image 1"
            />
          ) : activeImage === 2 ? (
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/qbn68jjzygl7jw6abxi3"
              className={`img-fluid ${styles.imgfluid}`}
              alt="Image 2"
            />
          ) : activeImage === 3 ? (
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/chikleg67ucrdej0h8eh"
              className={`img-fluid ${styles.imgfluid}`}
              alt="Image 2"
            />
          ) : activeImage === 4 ? (
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/u3itln1mdgyr0ton9x18"
              className={`img-fluid ${styles.imgfluid}`}
              alt="Image 2"
            />
          ) : (
            ""
          )}
        </div>
        {/* left section */}
        <div className={styles.leftSection}>
          <div
            className={`${styles.inacitvecontaner} ${
              activeImage == 1 ? styles.actice : ""
            } `}
            onClick={() => handleSetActiveImage(1)}
          >
            <span className={styles.h2}>AI Resume Builder</span>
            {activeImage == 1 ? (
              <p className={styles.activeDiv}>
                {" "}
                Create a professional resume with industry-specific templates,
                smart suggestions, and real-time previews. Optimized for ATS, it
                helps users build resumes that stand out to recruiters.
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${styles.inacitvecontaner} ${
              activeImage == 2 ? styles.actice : ""
            } `}
            onClick={() => handleSetActiveImage(2)}
          >
            <span className={styles.h2}>Resume Analysis Score</span>
            {activeImage == 2 ? (
              <p className={styles.activeDiv}>
                {" "}
                Evaluate your resume with a detailed score on keyword usage,
                clarity, and ATS compatibility, and receive actionable
                improvement suggestions for each section.
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${styles.inacitvecontaner} ${
              activeImage == 3 ? styles.actice : ""
            } `}
            onClick={() => handleSetActiveImage(3)}
          >
            <span className={styles.h2}>ATS Job Match</span>
            {activeImage == 3 ? (
              <p className={styles.activeDiv}>
                {" "}
                Compare your resume to job descriptions, receive an ATS
                compatibility score, and get personalized suggestions to improve
                alignment with the role.
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${styles.inacitvecontaner} ${
              activeImage == 4 ? styles.actice : ""
            } `}
            onClick={() => handleSetActiveImage(4)}
          >
            <span className={styles.h2}>Job Recommendation</span>
            {activeImage == 4 ? (
              <p className={styles.activeDiv}>
                {" "}
                Get personalized job suggestions based on your resume and career
                goals, with options to filter by location, industry, and other
                preferences.
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
